import { StateParameterRedirectUri } from '~/modules/auth/runtime/utils/state-utils';

export default function useSignOutRoute(redirectUri?: string) {
  const {
    public: {
      auth: {
        route: { signOut: signOutRoute },
      },
    },
  } = useRuntimeConfig();

  return computed<string>(() => {
    const query = new URLSearchParams(redirectUri ? { [StateParameterRedirectUri]: redirectUri } : undefined);

    return `${signOutRoute}${query.size > 0 ? `?${query.toString()}` : ''}`;
  });
}

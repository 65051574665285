<i18n lang="json" locale="fr-FR">
{
  "label": {
    "show": "Afficher le menu",
    "hide": "Cacher le menu"
  }
}
</i18n>

<script setup lang="ts">
import { OdsVisuallyHidden } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import type { UseMainNavComposable } from '~/composables/useMainNav';
import { injectOrFail } from '~/utils/ReactivityUtils';

defineProps<{
  sticky?: boolean;
}>();

const toggleMenu = injectOrFail<UseMainNavComposable['toggleMenu']>('toggleMenu');
const isActive = injectOrFail<UseMainNavComposable['isActive']>('isActive');
const MEGA_MENU_ID = injectOrFail<string>('MEGA_MENU_ID');
const SIDE_NAV_ID = injectOrFail<string>('SIDE_NAV_ID');

const { t } = useI18n();

const toggleButtonLabel = computed(() => (isActive.value ? t('label.hide') : t('label.show')));
</script>

<template>
  <button
    type="button"
    class="layout-default-header-bar-main-nav-button__toggle-menu"
    :class="{
      'layout-default-header-bar-main-nav-button__toggle-menu--sticky': sticky,
    }"
    :aria-controls="`${MEGA_MENU_ID} ${SIDE_NAV_ID}`"
    :aria-expanded="isActive"
    :title="toggleButtonLabel"
    @click="toggleMenu"
  >
    <LayoutDefaultHeaderNavIconToggle
      aria-hidden="true"
      class="layout-default-header-bar-main-nav-button__toggle-menu-icon"
      :active="isActive"
    />

    <OdsVisuallyHidden>{{ toggleButtonLabel }}</OdsVisuallyHidden>
  </button>
</template>

<style lang="scss">
@use '~/assets/scss/header-nav-utils' as header-nav-utils;

$layout-default-header-bar-logo: &;

.layout-default-header-bar-main-nav-button {
  &__toggle-menu {
    border: 0;
    padding: 0;
    margin: 0 0 var(--spacing--xs) 0;
    font: inherit;
    background: none;
    color: inherit;
    cursor: pointer;
    font-size: 1.5em;
    display: block;

    @include header-nav-utils.breakpoint {
      display: none;

      &--sticky {
        display: block;
      }
    }
  }
}
</style>

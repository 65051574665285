import useAuthenticatedOrganization from '~/modules/auth/runtime/composables/useAuthenticatedOrganization';
import type { User } from '~/utils/api/organization/user-organization/utils';

export class AuthenticatedOrganizationUserError extends Error {}

export default function useAuthenticatedOrganizationUser() {
  return useNuxtApp().$authUtils.getOrSetRequestAuthStorageEntry('useAuthenticatedOrganizationUser', async () => {
    const { data: organization, error } = await useAuthenticatedOrganization();

    if (error.value) {
      throw new AuthenticatedOrganizationUserError('Failed to get the authenticated organization');
    }

    return computed<User | undefined>(() => {
      if (organization.value) {
        const { parsedJwt } = useAuth();
        const authenticatedUserId = parsedJwt.value?.oid;

        if (authenticatedUserId) {
          const organizationUser = organization.value.userList.find((user) => user.id === authenticatedUserId);

          if (!organizationUser) {
            throw new AuthenticatedOrganizationUserError('User not found in organization');
          }

          return organizationUser;
        }
      }

      return;
    });
  });
}

<i18n lang="json" locale="fr-FR">
{
  "action": {
    "goBack": {
      "label": "@:{'global.action.goBack'}",
      "title": "@:{'action.goBack.label'}"
    }
  }
}
</i18n>

<script setup lang="ts">
import { OdsIcon, OdsVisuallyHidden } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

withDefaults(
  defineProps<{
    title?: string;
    disableGoBack?: boolean;
  }>(),
  {
    title: undefined,
  },
);

const emit = defineEmits<{
  goBack: [];
}>();

const { t } = useI18n();

const isActive = defineModel<boolean>('active');

const onGoBackClick = () => emit('goBack');
</script>

<template>
  <div
    class="layout-default-header-side-menu-layer"
    :class="{ 'layout-default-header-side-menu-layer--active': isActive }"
  >
    <div
      v-if="!disableGoBack || $slots.header || title || $slots.title"
      class="layout-default-header-side-menu-layer__header"
    >
      <slot name="header">
        <button
          v-if="!disableGoBack"
          type="button"
          :title="t('action.goBack.title')"
          class="layout-default-header-side-menu-layer__go-back"
          @click="onGoBackClick"
        >
          <OdsIcon class="layout-default-header-side-menu-layer__go-back-icon" name="arrow-back" aria-hidden="true" />

          <OdsVisuallyHidden>{{ t('action.goBack.label') }}</OdsVisuallyHidden>
        </button>

        <div v-if="title || $slots.title" class="layout-default-header-side-menu-layer__header-title">
          <slot name="title">{{ title }}</slot>
        </div>
      </slot>
    </div>

    <div class="layout-default-header-side-menu-layer__body">
      <slot name="body">
        <slot></slot>
      </slot>
    </div>
  </div>
</template>

<style lang="scss">
.layout-default-header-side-menu-layer {
  --layout-default-header-side-menu-layer--transition-duration: var(
    --layout-default-header-side-menu--transition-duration,
    var(--default--transition-duration)
  );
  --layout-default-header-side-menu-layer--header-background: var(--nav-bar-dropdown-menu-nav-item--active-bg-color);
  --layout-default-header-side-menu-layer--header-color: var(--semantic--success);
  --layout-default-header-side-menu-layer--header-color-emphasis: var(--scheme--card-highlighted-color-emphasis);

  transform: translateX(100%);
  transition: transform var(--layout-default-header-side-menu-layer--transition-duration);

  &--active {
    transform: translateX(0);
  }

  &__header {
    position: sticky;
    top: 0;
    background: var(--layout-default-header-side-menu-layer--header-background);
    display: flex;
    align-items: center;
    gap: var(--spacing--s);
    padding: var(--spacing--s) var(--layout-default-header-side-menu--gutter) var(--spacing--s)
      var(--layout-default-header-side-menu--start-gutter);
    color: var(--layout-default-header-side-menu-layer--header-color);
    font-weight: var(--h6--font-weight);
    margin: 0 0 var(--spacing--s) 0;

    /* stylelint-disable scss/operator-no-newline-after */
    min-height: calc(
      2 * var(--layout-default-header-side-menu-layer--header-font-size) *
        var(--layout-default-header-side-menu-layer--header-line-height)
    );
    /* stylelint-enable scss/operator-no-newline-after */
  }

  &__header-title {
    flex: 1;
    min-width: 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    line-height: 1.2;
  }

  &__go-back {
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
    background: none;
    border: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__go-back-icon {
    font-size: 1.5em;
  }

  &__body {
    padding-bottom: env(safe-area-inset-bottom);
  }
}
</style>

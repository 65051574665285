<i18n lang="json" locale="fr-FR">
{
  "extranetAccess": "Accès extranet",
  "welcome": "Bienvenue {name}",
  "info": "Vous êtes connecté en tant que fournisseur. Vous n'aurez pas la possibilité de créer un compte ou de valider un panier."
}
</i18n>

<script lang="ts" setup>
import { OdsButton, OdsColorScheme } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import { useRuntimeConfig } from '#app';

const { t } = useI18n();
const user = await useAuthenticatedOrganizationUser();
const {
  public: { backOfficeUrl },
} = useRuntimeConfig();
</script>

<template>
  <OdsColorScheme scheme="tertiary" skip-root-tag>
    <div>
      <div class="layout-default-supplier-pre-header">
        <div>
          <div class="layout-default-supplier-pre-header__welcome">
            {{ t('welcome', { name: `${user?.firstName ?? ''} ${user?.lastName ?? ''}` }) }}
          </div>

          <div>{{ t('info') }}</div>
        </div>

        <OdsButton :to="backOfficeUrl" :label="t('extranetAccess')" />
      </div>
    </div>
  </OdsColorScheme>
</template>

<style lang="scss">
.layout-default-supplier-pre-header {
  display: flex;
  align-items: center;
  gap: var(--spacing--l);
  justify-content: space-between;
  padding: var(--spacing--m);

  &__welcome {
    font-weight: bold;
  }
}
</style>

<i18n lang="json" locale="fr-FR">
{
  "phone": "@:{'global.support.phone.local'}",
  "openLabel": "Ouvert"
}
</i18n>

<script setup lang="ts">
import { useI18n } from 'vue-i18n';

import { formatPhoneNumber } from '~/utils/PhoneNumberUtils';

const { t } = useI18n();

const phoneNumberLabel = formatPhoneNumber(t('phone'), ' ');
</script>

<template>
  <span class="phone-badge">
    <span class="phone-badge__open-indicator"></span>

    <span class="phone-badge__open-label">{{ t('openLabel') }}</span>

    <a :href="`tel:${t('phone')}`" class="phone-badge__phone-number">{{ phoneNumberLabel }}</a>
  </span>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.phone-badge {
  display: inline-flex;
  width: fit-content;
  gap: var(--spacing--xs);
  padding-inline: var(--spacing--xs);
  align-items: center;
  border-radius: var(--radius--l);
  color: var(--scheme--background);
  background-color: var(--scheme--color);

  $indicator-color: #04be00;

  &__open-indicator {
    width: ods.px-to-em(6px);
    height: ods.px-to-em(6px);
    border-radius: 50%;
    background-color: $indicator-color;
  }

  &__open-label {
    display: none;
    color: $indicator-color;
  }

  &__phone-number {
    font-weight: 700;
  }
}
</style>

<script setup lang="ts">
import { OdsClickableElement, OdsIcon } from '@onliz/design-system-vue';
import { computed } from 'vue';

import type { NavItemConfig } from '~/utils/MainNavUtils';

const props = withDefaults(
  defineProps<
    Pick<Partial<NavItemConfig>, 'label' | 'icon' | 'children'> & {
      active?: boolean;
    }
  >(),
  {
    label: undefined,
    icon: undefined,
    children: () => [],
  },
);

const hasChildren = computed<boolean>(() => props.children.length > 0);
</script>

<template>
  <OdsClickableElement
    :aria-haspopup="hasChildren ? 'true' : undefined"
    class="layout-default-header-nav-bar-dropdown-menu-nav-item"
    :class="{ 'layout-default-header-nav-bar-dropdown-menu-nav-item--active': active }"
  >
    <span v-if="icon || $slots.prepend" class="layout-default-header-nav-bar-dropdown-menu-nav-item__prepend">
      <slot name="prepend">
        <LayoutDefaultHeaderNavIcon v-if="icon" :config="icon" />
      </slot>
    </span>

    <span v-if="label || $slots.default" class="layout-default-header-nav-bar-dropdown-menu-nav-item__label">
      <slot>{{ label }}</slot>
    </span>

    <span v-if="$slots.append || hasChildren" class="layout-default-header-nav-bar-dropdown-menu-nav-item__append">
      <slot name="append">
        <!-- TODO Update icon -->
        <OdsIcon name="chevron-right" aria-hidden="true" />
      </slot>
    </span>
  </OdsClickableElement>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

:root {
  --nav-bar-dropdown-menu-nav-item--active-color: var(--semantic--success);
  --nav-bar-dropdown-menu-nav-item--active-bg-color: #defaf7;
  --nav-bar-dropdown-menu-nav-item--icon-size: 1.8em;
}

.layout-default-header-nav-bar-dropdown-menu-nav-item {
  display: flex;
  align-items: center;
  gap: var(--spacing--s);
  padding: var(--spacing--s) var(--spacing--m);
  background: none;
  font-family: inherit;
  font-size: var(--small--font-size);
  font-weight: inherit;
  line-height: inherit;
  margin: 0;
  border: 0;
  color: inherit;
  width: 100%;
  text-align: inherit;
  transition:
    color var(--default--transition-duration),
    background-color var(--default--transition-duration);
  cursor: pointer;

  &:hover {
    color: var(--nav-bar-dropdown-menu-nav-item--active-color);
  }

  &--active {
    color: var(--nav-bar-dropdown-menu-nav-item--active-color);
    background-color: var(--nav-bar-dropdown-menu-nav-item--active-bg-color);
    font-weight: bold;
  }

  &__label {
    flex: 1;
  }

  &__append {
    line-height: 0;
    font-size: var(--nav-bar-dropdown-menu-nav-item--icon-size);
  }
}
</style>

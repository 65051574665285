<script lang="ts" setup>
import { OdsClickableElement } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import { AisRefinementList } from 'vue-instantsearch/vue3/es';
import type { RouteLocationRaw } from 'vue-router';

import { useCategoryTree } from '~/composables/catalog';

const { data: categories } = await useCategoryTree();

const getItemCategories = (value: string): { label: string; route: RouteLocationRaw }[] => {
  const categories = value.split(' > ');

  return categories.map((category, index) => {
    const slug = categories.slice(0, index + 1);

    return {
      label: category,
      route: helpers.route({
        name: 'cat-slug',
        params: { slug },
      }),
    };
  });
};
</script>

<template>
  <AisRefinementList attribute="categories.lvl2" :limit="3">
    <template #default="{ items }">
      <ul
        v-for="item in items"
        :key="`search-panel-cat-${item.value}`"
        class="layout-default-header-search-panel-categories"
      >
        <nav class="layout-default-header-search-panel-categories__nav">
          <ol class="layout-default-header-search-panel-categories__list">
            <li
              v-for="category in getItemCategories(item.value)"
              :key="`search-panel-cat-item-${category.label}`"
              class="layout-default-header-search-panel-categories__item"
            >
              <OdsClickableElement :to="category.route" class="layout-default-header-search-panel-categories__link">
                <span class="layout-default-header-search-panel-categories__label">
                  {{ categories?.dict[category.label]?.label ?? category.label }}
                </span>
              </OdsClickableElement>
            </li>
          </ol>
        </nav>
      </ul>
    </template>
  </AisRefinementList>
</template>

<style lang="scss">
.layout-default-header-search-panel-categories {
  margin: 0;

  &__list {
    display: inline-flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;

    & + & {
      margin: var(--spacing--s) 0 0 0;
    }
  }

  &__item {
    display: inline;

    &::after {
      content: '/';
      margin: 0 var(--spacing--xs);
    }

    &:last-of-type {
      font-weight: bold;

      &::after {
        content: none;
      }
    }
  }

  &__link {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

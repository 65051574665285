<i18n lang="json" locale="fr-FR">
{
  "placeholder": "Rechercher un produit…"
}
</i18n>

<script setup lang="ts">
import { OdsClickableElement, OdsIcon } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import { useI18n } from 'vue-i18n';

const emit = defineEmits<{
  panelOpen: [];
  panelClosed: [];
  submit: [];
}>();

const query = defineModel<string>({ default: '' });
const { t } = useI18n();

const route = useRoute();

const clearQuery = () => {
  query.value = '';
};

const searchInputRef = ref<HTMLInputElement>();

const hasQuery = computed<boolean>(() => {
  return query.value.length > 0;
});

const onInputFocus = () => emit('panelOpen');

const onEscKeyUp = () => {
  blurInput();
};

const blurInput = () => {
  if (searchInputRef.value) {
    searchInputRef.value.blur();
  }

  emit('panelClosed');
};

const onSubmit = async () => {
  emit('submit');

  await navigateTo(
    helpers.route({
      name: 'p-search',
      query: { query: query.value },
    }),
    {
      replace: true,
    },
  );

  blurInput();
};

watch(
  () => route.path,
  () => {
    if (route.name !== 'p-search') {
      clearQuery();
    }
  },
);
</script>

<template>
  <div class="search-bar">
    <input
      ref="searchInputRef"
      v-model="query"
      class="search-bar__input"
      type="search"
      :placeholder="t('placeholder')"
      autocomplete="off"
      @focus="onInputFocus"
      @keyup.esc="onEscKeyUp"
      @keyup.enter="onSubmit"
    />

    <div class="search-bar__controls" :class="{ 'search-bar__controls--active': hasQuery }">
      <TransitionScaleUp>
        <OdsClickableElement
          v-if="hasQuery"
          class="search-bar__controls-btn search-bar__controls-btn--clear"
          @click="clearQuery"
        >
          <OdsIcon class="search-bar__icon" name="cancel" />
        </OdsClickableElement>
      </TransitionScaleUp>

      <OdsClickableElement class="search-bar__controls-btn search-bar__controls-btn--submit" @click="onSubmit">
        <OdsIcon class="search-bar__icon" name="search" />
      </OdsClickableElement>
    </div>
  </div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.search-bar {
  position: relative;

  $block-selector: &;

  &__input {
    width: 100%;
    border-radius: var(--radius--m);
    border: 1px solid var(--scheme--border);
    background-color: var(--greyscale--white);
    box-shadow: var(--shadow--m);
    padding: var(--spacing--s) var(--spacing--m);
    outline: 2px solid transparent;
    transition: 300ms outline-color ods.$ease-out-circ;
    color: var(--scheme--color);

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }

    &::placeholder {
      color: var(--greyscale--light-grey);
    }

    &:focus {
      outline-color: var(--scheme--color-emphasis);
    }
  }

  &__controls {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 1.5em;
    padding: var(--spacing--xs) 0;
  }

  &__controls-btn {
    background: none;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: var(--spacing--s);
    height: 100%;

    &--submit {
      font-size: 1.15em;
      border-left: 1px solid transparent;
      transition: 1200ms border-color ods.$ease-out-circ;

      #{$block-selector}__controls--active & {
        border-color: var(--scheme--border);
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__icon {
    color: var(--scheme--color);

    &:hover {
      color: var(--scheme--color-emphasis);
    }
  }
}
</style>

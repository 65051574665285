<i18n lang="json" locale="fr-FR">
{
  "basket": "Panier"
}
</i18n>

<script setup lang="ts">
import { OdsButton, OdsIcon } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { basketCount } = await useBasket();

const basketRoute = helpers.route({ name: 'basket' });
</script>

<template>
  <div class="layout-default-header-bar-basket">
    <OdsButton type="button" :label="t('basket')" :to="basketRoute">
      <template #prepend>
        <OdsIcon class="layout-default-header-bar-basket__icon" name="local-mall" aria-hidden="true" />
      </template>
    </OdsButton>

    <div v-if="basketCount" class="layout-default-header-bar-basket__badge">
      {{ basketCount }}
    </div>
  </div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-default-header-bar-basket {
  position: relative;

  &__icon {
    font-size: 1.5em;
  }

  &__badge {
    $badge-size: 1.375rem;

    position: absolute;
    right: calc($badge-size * -0.25); // Make the badge 25% offset from the right edge
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--semantic--info-background);
    background-color: var(--semantic--warning);
    color: var(--semantic--info-background-light);
    font-size: var(--extra-small--font-size);
    border-radius: 50%;
    width: $badge-size;
    height: $badge-size;
    pointer-events: none;
  }

  .ods-button__body {
    display: none;

    @media screen and (min-width: ods.$breakpoint-xs) {
      display: grid;
    }
  }
}
</style>

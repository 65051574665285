<i18n lang="json" locale="fr-FR">
{
  "yourAccount": "Votre compte",
  "yourOrders": "Vos commandes",
  "yourAddresses": "Vos adresses",
  "logOut": "Se déconnecter"
}
</i18n>

<script setup lang="ts">
import { OdsDropdown, OdsDropdownItem, OdsIcon } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import { TeleportTargetBodyEnd } from '~/utils/TeleportUtils';

const { t } = useI18n();
const user = await useAuthenticatedOrganizationUser();
const isExpanded = ref(false);
const logoutRoute = useSignOutRoute();
</script>

<template>
  <OdsDropdown v-model="isExpanded" :teleport-destination="TeleportTargetBodyEnd.targetSelector" align-x="right">
    <template #trigger>
      <div class="layout-default-header-bar-user-menu__trigger">
        <OdsIcon name="person" aria-hidden="true" />

        <div v-if="user" class="layout-default-header-bar-user-menu__trigger-label">
          {{ user.firstName }}
        </div>
      </div>
    </template>

    <template #dropdown>
      <OdsDropdownItem to="/customer/account/profile" :label="t('yourAccount')" />

      <OdsDropdownItem to="/customer/orders/signing" :label="t('yourOrders')" />

      <OdsDropdownItem to="/customer/addresses" :label="t('yourAddresses')" />

      <OdsDropdownItem :href="logoutRoute" :label="t('logOut')" />
    </template>
  </OdsDropdown>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-default-header-bar-user-menu {
  &__trigger {
    display: flex;
    align-items: center;
    gap: var(--spacing--s);
  }

  &__trigger-label {
    display: none;

    @include ods.breakpoint(ods.$breakpoint-xs) {
      display: block;
    }
  }
}
</style>

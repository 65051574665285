<i18n lang="json" locale="fr-FR">
{
  "action": {
    "viewAllProducts": {
      "label": "@:{'global.action.viewAllProducts.label'}",
      "title": "@:{'global.action.viewAllProducts.title'}"
    }
  }
}
</i18n>

<script lang="ts" setup>
import { OdsColorScheme } from '@onliz/design-system-vue';
import { type MaybeElement, unrefElement, useMounted } from '@vueuse/core';
import { type CSSProperties, type DeepReadonly, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

import LayoutDefaultHeaderNavBarDropdownMenuLayer from '~/components/layout/default/header/navBar/dropdownMenu/LayoutDefaultHeaderNavBarDropdownMenuLayer.vue';
import LayoutDefaultHeaderNavBarDropdownMenu from '~/components/layout/default/header/navBar/LayoutDefaultHeaderNavBarDropdownMenu.vue';
import LayoutContainer from '~/components/layout/LayoutContainer.vue';
import { PRODUCT_NAV_ITEM_ID } from '~/composables/layout/default/header/useHeaderNavData';
import type { UseMainNavComposable } from '~/composables/useMainNav';
import { toPx } from '~/utils/CssUtils';
import { type NavItemConfig } from '~/utils/MainNavUtils';
import { injectOrFail } from '~/utils/ReactivityUtils';

defineProps<{
  navTree: NavItemConfig[];
}>();

const { t } = useI18n();

const isMegaMenu = injectOrFail<Readonly<UseMainNavComposable['isMegaMenu']>>('isMegaMenu');
const isMegaMenuActive = injectOrFail<UseMainNavComposable['isMegaMenuActive']>('isMegaMenuActive');
const isActiveNodeId = injectOrFail<UseMainNavComposable['isActiveNodeId']>('isActiveNodeId');
const isSticky = injectOrFail<DeepReadonly<Ref<boolean>>>('isSticky');
const headerBarRef = injectOrFail<MaybeElement>('headerBarRef');
const toggleMainNavButton = injectOrFail<Ref<HTMLElement>>('toggleMainNavButton');
const clearActiveNode = injectOrFail<UseMainNavComposable['clearActiveNode']>('clearActiveNode');
const getNavItemProps = injectOrFail<UseMainNavComposable['getNavItemProps']>('getNavItemProps');
const layerMap = injectOrFail<UseMainNavComposable['layerMap']>('layerMap');
const onNavItemClick = injectOrFail<UseMainNavComposable['onNavItemClick']>('onNavItemClick');
const activeNodeId = injectOrFail<UseMainNavComposable['activeNodeId']>('activeNodeId');
const MEGA_MENU_ID = injectOrFail<string>('MEGA_MENU_ID');

const isMounted = useMounted();

const navBarRef = ref<MaybeElement>();
const targetStyle = ref<CSSProperties>();

const updateTargetStyle = () => {
  const triggerElement = isSticky.value ? unrefElement(headerBarRef) : unrefElement(navBarRef);

  if (triggerElement) {
    const { top, height } = triggerElement.getBoundingClientRect();

    if (document.scrollingElement === null) {
      throw new Error('scrollingElement is null');
    }

    const { clientHeight } = document.scrollingElement;

    targetStyle.value = {
      top: toPx(top + height),
      maxHeight: toPx(clientHeight - top - height),
    };
  }
};

onMounted(() => {
  updateTargetStyle();
});

const onMegaMenuStateUpdate = (isActive: boolean) => {
  if (!isActive) {
    clearActiveNode();
  }
};

const navBarActionRef = ref<MaybeElement[]>([]);
</script>

<template>
  <OdsColorScheme skip-root-tag scheme="primary">
    <LayoutContainer
      ref="navBarRef"
      class="layout-default-header-nav-bar"
      :class="{
        'layout-default-header-nav-bar--active': isMegaMenuActive,
        'layout-default-header-nav-bar--sticky': isSticky,
      }"
      :hidden="isMounted && !isMegaMenu"
    >
      <div class="layout-default-header-nav-bar__container">
        <nav
          v-for="{ id, children } in navTree"
          :key="id"
          class="layout-default-header-nav-bar__nav"
          :class="`layout-default-header-nav-bar__nav--${id}`"
        >
          <ul class="layout-default-header-nav-bar__nav-list">
            <li
              v-for="item in children"
              :key="item.id"
              class="layout-default-header-nav-bar__nav-item"
              :class="{ 'layout-default-header-nav-bar__nav-item--products': item.id === PRODUCT_NAV_ITEM_ID }"
            >
              <LayoutDefaultHeaderNavBarNavAction
                v-bind="getNavItemProps(item, layerMap.navLayers.get(item.id)?.megaMenuId)"
                ref="navBarActionRef"
                class="layout-default-header-nav-bar__nav-item-action"
                @click="() => onNavItemClick(item)"
              >
                <template v-if="item.id === PRODUCT_NAV_ITEM_ID" #prepend>
                  <LayoutDefaultHeaderNavIconToggle :active="isMegaMenuActive" aria-hidden="true" />
                </template>
              </LayoutDefaultHeaderNavBarNavAction>
            </li>
          </ul>
        </nav>
      </div>

      <LayoutDefaultHeaderNavBarDropdownMenu
        :id="MEGA_MENU_ID"
        :active="isMegaMenu && isMegaMenuActive"
        :click-outside-ignore-list="[toggleMainNavButton, ...navBarActionRef]"
        :style="targetStyle"
        @request-style-update="updateTargetStyle"
        @update:active="onMegaMenuStateUpdate"
      >
        <template #body>
          <div class="layout-default-header-nav-bar__layer-stack">
            <template v-for="([id, { item, megaMenuId, ancestorIdList }], index) in layerMap.navLayers" :key="id">
              <LayoutDefaultHeaderNavBarDropdownMenuLayer
                :id="megaMenuId"
                :is-first-active="ancestorIdList.length === 1"
                class="layout-default-header-nav-bar__layer"
                :class="{
                  'layout-default-header-nav-bar__layer--active': isActiveNodeId(id),
                  'layout-default-header-nav-bar__layer--depth-1': ancestorIdList.length === 1,
                }"
                :style="{ '--depth': ancestorIdList.length }"
              >
                <div class="layout-default-header-nav-bar__layer-container">
                  <ul class="layout-default-header-nav-bar__dropdown-nav">
                    <li
                      v-if="item.id === PRODUCT_NAV_ITEM_ID || ancestorIdList.includes(PRODUCT_NAV_ITEM_ID)"
                      class="layout-default-header-nav-bar__dropdown-nav-item"
                    >
                      <LayoutDefaultHeaderNavBarDropdownMenuNavItem
                        v-if="index > 0"
                        :to="item.to"
                        class="layout-default-header-nav-bar__dropdown-nav-item-action layout-default-header-nav-bar__nav-item-action--view-all-products"
                        :title="t('action.viewAllProducts.title')"
                        @click="() => onNavItemClick(item)"
                      >
                        {{ t('action.viewAllProducts.label') }}
                      </LayoutDefaultHeaderNavBarDropdownMenuNavItem>
                    </li>

                    <li
                      v-for="child in item.children"
                      :key="child.id"
                      class="layout-default-header-nav-bar__dropdown-nav-item"
                    >
                      <LayoutDefaultHeaderNavBarDropdownMenuNavItem
                        v-bind="getNavItemProps(child, layerMap.navLayers.get(child.id)?.megaMenuId)"
                        class="layout-default-header-nav-bar__dropdown-nav-item-action"
                        @click="() => onNavItemClick(child, item.id)"
                      />
                    </li>
                  </ul>
                </div>
              </LayoutDefaultHeaderNavBarDropdownMenuLayer>
            </template>
          </div>
        </template>

        <template v-if="layerMap.asideLayers.size > 0" #aside>
          <div class="layout-default-header-nav-bar__aside-layer-stack">
            <div
              v-for="[id, { item, asideId }] in layerMap.asideLayers"
              :id="asideId"
              :key="id"
              class="layout-default-header-nav-bar__aside-layer"
              :class="{
                'layout-default-header-nav-bar__aside-layer--active':
                  activeNodeId !== undefined && layerMap.navLayers.get(activeNodeId)?.asideToDisplay === id,
              }"
            >
              <!-- TODO Implement aside content -->
              {{ item.aside }}
            </div>
          </div>
        </template>
      </LayoutDefaultHeaderNavBarDropdownMenu>
    </LayoutContainer>
  </OdsColorScheme>
</template>

<style lang="scss">
@use '~/assets/scss/header-nav-utils' as header-nav-utils;

.layout-default-header-nav-bar {
  $block-selector: &;

  --layer-padding-left--depth-1: max(var(--layout-container-gutter), env(safe-area-inset-left));
  --layer-transition-duration: var(--default--transition-duration);

  display: none;

  @include header-nav-utils.breakpoint {
    display: revert;
  }

  &__container {
    font-size: var(--small--font-size);
    border-top: solid 1px var(--scheme--border);
    display: flex;
    gap: var(--spacing--m);

    #{$block-selector}--sticky & {
      display: none;
    }
  }

  &__nav {
    &--secondary-nav {
      margin-left: auto;
    }
  }

  &__nav-list {
    display: flex;
    align-items: center;
  }

  &__nav-item {
    &--products {
      display: flex;
      align-items: center;

      &::after {
        content: '';
        background-color: var(--scheme--border);
        width: 1px;
        display: block;
        height: 1.125rem;
        margin-left: var(--spacing--xs);
        margin-right: var(--spacing--xs);
      }
    }
  }

  &__nav-item-action {
    #{$block-selector}__nav-item:first-child & {
      padding-left: 0;
    }

    #{$block-selector}__nav-item:last-child & {
      padding-right: 0;
    }

    &--view-all-products {
      text-decoration: underline;
      font-size: var(--default--font-size);
      color: inherit;
    }
  }

  &__layer-stack {
    height: 100%;
    display: grid;
    grid-template-areas: 'content';
  }

  &__layer {
    $layer-selector: &;

    // Inner width
    --layer-inner-width--default: 18.75rem;
    --layer-inner-width--depth-1: 14.375rem;

    // Padding left
    --layer-padding-left--default: 0px;
    --layer-padding-left--depth-1: max(var(--layout-container-gutter), env(safe-area-inset-left));

    // Width
    --layer-width--default: calc(var(--layer-inner-width--default) + var(--layer-padding-left--default));
    --layer-width--depth-1: calc(var(--layer-inner-width--depth-1) + var(--layer-padding-left--depth-1));

    // Vars
    --layer-width: var(--layer-width--default);
    --layer-offset: calc(var(--layer-width--depth-1) + (var(--layer-width--default) * (var(--depth) - 2)));

    grid-area: content;
    overflow-y: auto;
    overscroll-behavior: contain;
    background: var(--scheme--background);
    visibility: hidden;
    width: var(--layer-width);

    &:not(#{$layer-selector}--depth-1) {
      transform: translateX(var(--layer-offset));
      transition: visibility 0s var(--layer-transition-duration);
    }

    &--active {
      visibility: visible;

      &:not(#{$layer-selector}--depth-1) {
        transition: visibility var(--layer-transition-duration);
        transform: translateX(var(--layer-offset));
      }

      & ~ & {
        border-left: solid 1px var(--scheme--border);
      }
    }

    &--depth-1 {
      --layer-width: var(--layer-width--depth-1);
      --layer-offset: 0px;
    }
  }

  &__layer-container {
    overflow: auto;
    transform: translateX(-100%);
    opacity: 0;

    #{$block-selector}__layer:not(#{$block-selector}__layer--depth-1) & {
      transition:
        transform var(--layer-transition-duration),
        opacity var(--layer-transition-duration);
    }

    #{$block-selector}__layer--active & {
      transform: translateX(0);
      opacity: 1;
    }
  }

  &__dropdown-nav-item-action {
    #{$block-selector}__layer--depth-1 & {
      padding-left: var(--layer-padding-left--depth-1);
    }
  }

  &__aside-layer-stack {
    height: 100%;
    display: grid;
    grid-template-areas: 'content';
  }

  &__aside-layer {
    --layer-transition-duration: 500ms;

    grid-area: content;
    overflow-y: auto;
    overscroll-behavior: contain;
    visibility: hidden;
    opacity: 0;
    transition:
      visibility 0s var(--layer-transition-duration),
      opacity var(--layer-transition-duration);
    padding-top: var(--spacing--m);
    padding-bottom: var(--spacing--m);

    &--active {
      visibility: visible;
      opacity: 1;
      transform: translateX(0);
      transition:
        visibility var(--layer-transition-duration),
        opacity var(--layer-transition-duration);
      z-index: 1;
    }
  }
}
</style>

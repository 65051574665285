import { callWithNuxt } from '#app';
import { useCmsNavigation } from '~/composables/useCmsNavigation';
import { CACHE_TTL_S_12_HOURS, CACHE_TTL_S_15_MIN, NAVIGATION_TAG } from '~/lib/cache-utils';
import { type CategoryCode, type CategoryTreeItem, getCategoryTree } from '~/utils/api/catalog/categories';
import type { NavItemConfig } from '~/utils/MainNavUtils';
import {
  mapCategoryTreeItemToNavItemConfig,
  mapCmsNavItemToNavItemConfig,
  sortMayBeList,
} from '~/utils/UseHeaderNavDataUtils';

export const MAIN_NAV_ID = 'main-nav';
export const SECONDARY_NAV_ID = 'secondary-nav';
export const PRODUCT_NAV_ITEM_ID = 'products';

async function getHeaderNavData() {
  const categoryIndex = new Map<CategoryCode, CategoryTreeItem>();

  const [{ data: headerMainData }, { data: headerAsideData }, categoryTree] = await Promise.all([
    useCmsNavigation(CmsHeaderNavigationSlug.main),
    useCmsNavigation(CmsHeaderNavigationSlug.aside),
    getCategoryTree((categoryTreeItem) => {
      categoryIndex.set(categoryTreeItem.code, categoryTreeItem);
    }),
  ]);

  const tree: NavItemConfig[] = [
    {
      id: MAIN_NAV_ID,
      children: [
        {
          id: PRODUCT_NAV_ITEM_ID,
          label: 'Nos produits',
          children: categoryTree.reduce<NavItemConfig[]>((acc, node) => {
            // Remove categories without any product
            if (node.productCount) {
              acc.push(mapCategoryTreeItemToNavItemConfig(node, categoryIndex));
            }

            return acc;
          }, []),
        },
        ...sortMayBeList(headerMainData.value.renderNavigation).map((navItem) => mapCmsNavItemToNavItemConfig(navItem)),
      ],
    },
    {
      id: SECONDARY_NAV_ID,
      children: sortMayBeList(headerAsideData.value.renderNavigation).map((navItem) =>
        mapCmsNavItemToNavItemConfig(navItem),
      ),
    },
  ];

  return tree;
}

export default function useHeaderNavData() {
  return useCachedAsyncData(
    'header-nav',
    async (nuxtApp) => {
      return nuxtApp === undefined ? await getHeaderNavData() : await callWithNuxt(nuxtApp, getHeaderNavData, []);
    },
    {
      deep: false,
      serverMaxAge: CACHE_TTL_S_12_HOURS,
      clientMaxAge: CACHE_TTL_S_15_MIN,
      serverCacheTags: [NAVIGATION_TAG],
    },
  );
}

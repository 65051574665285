<script setup lang="ts">
import { OdsClickableElement } from '@onliz/design-system-vue';
import { computed } from 'vue';

import type { NavItemConfig } from '~/utils/MainNavUtils';

const props = withDefaults(
  defineProps<
    Pick<Partial<NavItemConfig>, 'label' | 'icon' | 'children' | 'title' | 'hidden' | 'href' | 'to' | 'target'>
  >(),
  {
    children: () => [],
  },
);

const hasChildren = computed<boolean>(() => props.children.length > 0);
</script>

<!-- TODO Handle aria-controls attribute -->
<template>
  <OdsClickableElement
    :aria-haspopup="hasChildren ? 'true' : undefined"
    class="layout-default-header-nav-bar-nav-action"
    v-bind="{ title, hidden, href, to, target }"
  >
    <span v-if="icon || $slots.prepend" class="layout-default-header-nav-bar-nav-action__prepend">
      <slot name="prepend">
        <LayoutDefaultHeaderNavIcon v-if="icon" :config="icon" class="layout-default-header-nav-bar-nav-action__icon" />
      </slot>
    </span>

    <span class="layout-default-header-nav-bar-nav-action__label">
      <slot name="label">{{ label }}</slot>
    </span>
  </OdsClickableElement>
</template>

<style lang="scss">
.layout-default-header-nav-bar-nav-action {
  display: flex;
  align-items: center;
  gap: var(--spacing--s);
  padding: var(--spacing--s);
  background: none;
  font: inherit;
  margin: 0;
  border: 0;
  color: inherit;
  transition: color var(--default--transition-duration);
  cursor: pointer;

  &:hover,
  &:focus {
    color: var(--scheme--color-emphasis);
  }

  &__prepend {
    display: flex;
    align-items: center;
  }
}
</style>

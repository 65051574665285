<i18n lang="json" locale="fr-FR">
{
  "loggedAs": {
    "operator": "Vous êtes connecté sur un compte opérateur",
    "crc": "Vous êtes connecté sur un compte service client"
  },
  "showSupplierProductsOnly": "Voir mes produits uniquement",
  "actions": {
    "logIn": "@:{'global.action.logIn'}"
  }
}
</i18n>

<script setup lang="ts">
import { OdsButton, OdsColorScheme, OdsIcon, OdsSwitch } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import useSupplierProductsOnly from '~/composables/useSupplierProductsOnly';

const { t } = useI18n();
const { isAuthenticated, isOrganizationUser, isSupplierUser, isCrcUser, isOperatorUser } = useAuth();
const { shouldDisplaySupplierProductsOnly } = useSupplierProductsOnly();
const signInRoute = useSignInRoute();
</script>

<template>
  <div v-if="isAuthenticated">
    <template v-if="isOrganizationUser">
      <LayoutDefaultHeaderBarUserMenu />
    </template>

    <OdsColorScheme v-else v-bind="{ scheme: 'secondary' }" class="layout-default-header-bar-user__special-user">
      <template v-if="isSupplierUser">
        <label class="layout-default-header-bar-user-switch__label" for="show-supplier-products-only">
          {{ t('showSupplierProductsOnly') }}
        </label>

        <OdsSwitch id="show-supplier-products-only" v-model="shouldDisplaySupplierProductsOnly" small />
      </template>

      <template v-else-if="isCrcUser">{{ t('loggedAs.crc') }}</template>

      <template v-else-if="isOperatorUser">{{ t('loggedAs.operator') }}</template>
    </OdsColorScheme>
  </div>

  <div v-else>
    <OdsButton class="layout-default-header-bar-user" variant="link" :href="signInRoute" :label="t('actions.logIn')">
      <template #prepend>
        <OdsIcon class="layout-default-header-bar-user__icon" name="person" aria-hidden="true" />
      </template>
    </OdsButton>
  </div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-default-header-bar-user {
  &__icon {
    font-size: 1.5em;
  }

  .ods-button__body {
    display: none;
    border-bottom: 0;
    padding-bottom: 0;

    &::before {
      display: none;
    }

    @media screen and (min-width: ods.$breakpoint-xs) {
      display: grid;
    }
  }
}

.layout-default-header-bar-user__special-user {
  display: flex;
  align-items: center;
  gap: var(--spacing--s);
  padding: var(--spacing--s) var(--spacing--m);
  border-radius: var(--radius--m);
  font-size: var(--extra-small--font-size);

  @media screen and (min-width: ods.$breakpoint-xs) {
    font-size: var(--small--font-size);
  }
}

.layout-default-header-bar-user-switch__label {
  cursor: pointer;
}
</style>

<script setup lang="ts">
import { OdsIcon } from '@onliz/design-system-vue';

import { searchPanelScrollElementId } from '~/utils/SearchPanelUtils';

defineProps<{
  sticky?: boolean;
}>();

const isSearchPanelOpen = injectOrFail<Ref<boolean>>('isSearchPanelOpen');

const { unlock } = useScrollLock(searchPanelScrollElementId);

const onClose = () => {
  isSearchPanelOpen.value = false;

  unlock();
};
</script>

<template>
  <button
    type="button"
    class="layout-default-header-bar-search-panel-close-button"
    :class="{
      'layout-default-header-bar-search-panel-close-button--sticky': sticky,
    }"
    @click="onClose"
  >
    <OdsIcon block name="arrow-back" aria-hidden="true" />
  </button>
</template>

<style lang="scss">
@use '~/assets/scss/header-nav-utils' as header-nav-utils;

.layout-default-header-bar-search-panel-close-button {
  border: 0;
  padding: 0;
  margin: 0 0 var(--spacing--xs) 0;
  font: inherit;
  background: none;
  color: inherit;
  cursor: pointer;
  font-size: 1.5em;

  @include header-nav-utils.breakpoint {
    display: none;

    &--sticky {
      display: block;
    }
  }
}
</style>

<script setup lang="ts">
import { OdsColorScheme, type OdsColorSchemeType } from '@onliz/design-system-vue';

import useSetHeaderHeight from '~/composables/useSetHeaderHeight';
import { injectOrFail } from '~/utils/ReactivityUtils';
import { searchPanelScrollElementId } from '~/utils/SearchPanelUtils';

const props = defineProps<{
  sticky?: boolean;
  scheme?: OdsColorSchemeType;
}>();

const { lock, unlock } = useScrollLock(searchPanelScrollElementId);
const { isSupplierUser } = useAuth();

const scheme = computed<OdsColorSchemeType>(() => {
  return props.sticky ? 'default' : 'primary';
});

const searchQuery = injectOrFail<Ref<string>>('searchQuery');
const lastSearchQuery = injectOrFail<Ref<string>>('lastSearchQuery');
const isSearchPanelOpen = injectOrFail<Ref<boolean>>('isSearchPanelOpen');

const onPanelOpen = async () => {
  isSearchPanelOpen.value = true;

  if (props.sticky) {
    lock();
  } else {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'auto',
    });

    await nextTick();

    lock();
  }
};

const onPanelClosed = () => {
  isSearchPanelOpen.value = false;
  unlock();
};
const headerBar = ref<HTMLElement>();

const onSubmit = () => {
  lastSearchQuery.value = searchQuery.value;
};

useSetHeaderHeight(headerBar);
</script>

<template>
  <OdsColorScheme ref="headerBar" :scheme>
    <template v-if="!sticky">
      <LayoutDefaultSupplierPreHeader v-if="isSupplierUser" />

      <LayoutDefaultPreHeader v-else />
    </template>

    <LayoutContainer
      class="layout-default-header-bar"
      :class="{
        'layout-default-header-bar--sticky': sticky,
      }"
    >
      <LayoutDefaultHeaderBarNavigation class="layout-default-header-bar__logo" :sticky />

      <div class="layout-default-header-bar__search">
        <SearchBar
          v-model="searchQuery"
          class="layout-default-header-bar__search-bar"
          @panel-open="onPanelOpen"
          @panel-closed="onPanelClosed"
          @submit="onSubmit"
        />
      </div>

      <div class="layout-default-header-bar__cta">
        <LayoutDefaultHeaderBarUser />

        <LayoutDefaultHeaderBarBasket />
      </div>
    </LayoutContainer>
  </OdsColorScheme>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;
@use '~/assets/scss/header-nav-utils' as header-nav-utils;

.layout-default-header-bar {
  $block-selector: &;

  display: grid;
  grid-gap: var(--spacing--s);
  padding-top: var(--spacing--s);
  padding-bottom: var(--spacing--s);
  align-items: center;
  grid-template:
    'logo cta' auto
    'search search' auto / 1fr auto;

  @include header-nav-utils.breakpoint {
    grid-template: 'logo search cta' auto / auto 1fr auto;
    grid-gap: var(--spacing--m);
  }

  &--sticky {
    grid-template: 'logo search cta' auto / auto 1fr auto;
  }

  &__logo {
    grid-area: logo;
    justify-self: start;
  }

  &__search {
    grid-area: search;

    @include header-nav-utils.breakpoint {
      max-width: 650px;
    }
  }

  &__cta {
    grid-area: cta;
    justify-self: end;
    display: flex;
    gap: var(--spacing--m);
    align-items: center;
  }

  &__logo,
  &__search,
  &__cta {
    align-self: center;
  }
}
</style>

<script setup lang="ts">
import { OdsColorScheme } from '@onliz/design-system-vue';
import { onClickOutside, type OnClickOutsideOptions, useEventListener, useThrottleFn } from '@vueuse/core';
import { onMounted } from 'vue';

const props = withDefaults(
  defineProps<{
    clickOutsideIgnoreList?: OnClickOutsideOptions['ignore'];
  }>(),
  {
    clickOutsideIgnoreList: () => [],
  },
);

const emit = defineEmits<{
  requestStyleUpdate: [];
}>();

const isActive = defineModel<boolean>('active');

const rootElRef = ref<HTMLDivElement>();

onMounted(() => {
  let cleanupOutsideListener: (() => void) | undefined;
  let cleanupScrollListener: (() => void) | undefined;
  let cleanupResizeListener: (() => void) | undefined;

  watch(
    isActive,
    (isActiveValue) => {
      if (isActiveValue) {
        emit('requestStyleUpdate');

        cleanupOutsideListener ??= onClickOutside(
          rootElRef,
          () => {
            isActive.value = false;
          },
          { ignore: props.clickOutsideIgnoreList },
        );

        cleanupScrollListener ??= useEventListener(window, 'scroll', () => {
          isActive.value = false;
        });

        cleanupResizeListener ??= useEventListener(
          window,
          'resize',
          useThrottleFn(
            () => {
              emit('requestStyleUpdate');
            },
            250,
            true,
          ),
        );
      } else {
        cleanupOutsideListener?.();
        cleanupOutsideListener &&= undefined;

        cleanupScrollListener?.();
        cleanupScrollListener &&= undefined;

        cleanupResizeListener?.();
        cleanupResizeListener &&= undefined;
      }
    },
    {
      immediate: isActive.value,
    },
  );
});
</script>

<template>
  <div
    ref="rootElRef"
    class="layout-default-header-nav-bar-dropdown-menu"
    :class="{ 'layout-default-header-nav-bar-dropdown-menu--active': isActive }"
  >
    <OdsColorScheme scheme="default" class="layout-default-header-nav-bar-dropdown-menu__container">
      <div class="layout-default-header-nav-bar-dropdown-menu__body">
        <slot name="body"></slot>
      </div>

      <div v-if="$slots.aside" class="layout-default-header-nav-bar-dropdown-menu__aside">
        <slot name="aside"></slot>
      </div>
    </OdsColorScheme>
  </div>
</template>

<style lang="scss">
.layout-default-header-nav-bar-dropdown-menu {
  $block-selector: &;

  overflow: hidden;
  position: fixed;
  left: 0;
  right: 0;
  pointer-events: none;
  padding-bottom: 1.875rem; // Add padding so container shadow is visible

  &__container {
    display: flex;
    gap: var(--spacing--m);
    max-height: inherit;
    padding-right: var(--layout-container-gutter);
    border-top: solid 1px var(--scheme--border);
    pointer-events: auto;
    transform: translateY(-100%);
    transition: transform var(--default--transition-duration);

    #{$block-selector}--active & {
      box-shadow: var(--shadow--m);
      transform: translateY(0);
    }
  }

  &__body {
    flex: 1;
    overflow: auto;
  }

  &__aside {
    flex: none;
    width: 20.3125rem; // 325px
    overflow: auto;
  }
}
</style>

<script setup lang="ts">
import { type MaybeElement } from '@vueuse/core';
import { provide, readonly } from 'vue';

import type LayoutDefaultHeaderBar from '~/components/layout/default/header/LayoutDefaultHeaderBar.vue';
import useHeaderNavData from '~/composables/layout/default/header/useHeaderNavData';
import useMainNav from '~/composables/useMainNav';

const { data: navTree } = await useHeaderNavData();
const { query } = useRoute();

const {
  activeNodeId,
  clearActiveNode,
  getNavItemProps,
  isActive,
  isActiveNodeId,
  isMegaMenu,
  isMegaMenuActive,
  isSideMenuActive,
  layerMap,
  onNavItemClick,
  setActiveNodeId,
  toggleMenu,
} = useMainNav(navTree);

const headerBarRef = ref<MaybeElement>();
const toggleMainNavButton = ref<HTMLElement>();
const isSticky = ref(false);
const isSearchPanelOpen = ref(false);
const lastSearchQuery = ref('');
const searchQuery = ref(query.query ?? '');

const currentSearchQuery = computed(() => {
  return searchQuery.value?.length > 0 ? searchQuery.value : lastSearchQuery.value;
});

provide('activeNodeId', readonly(activeNodeId));
provide('clearActiveNode', clearActiveNode);
provide('getNavItemProps', getNavItemProps);
provide('headerBarRef', readonly(headerBarRef));
provide('isActive', readonly(isActive));
provide('isActiveNodeId', isActiveNodeId);
provide('isSticky', readonly(isSticky));
provide('isMegaMenu', readonly(isMegaMenu));
provide('isMegaMenuActive', readonly(isMegaMenuActive));
provide('isSideMenuActive', isSideMenuActive);
provide('layerMap', readonly(layerMap));
provide('MEGA_MENU_ID', 'mega-menu');
provide('onNavItemClick', onNavItemClick);
provide('setActiveNodeId', setActiveNodeId);
provide('SIDE_NAV_ID', 'side-nav');
provide('toggleMainNavButton', toggleMainNavButton);
provide('toggleMenu', toggleMenu);
provide('lastSearchQuery', lastSearchQuery);
provide('searchQuery', searchQuery);
provide('isSearchPanelOpen', isSearchPanelOpen);
</script>

<template>
  <LayoutStickyBar
    v-model="isSticky"
    class="layout-default-header"
    :class="{ 'layout-default-header--sticky': isSticky }"
    :scheme="isMegaMenuActive ? 'default' : 'primary'"
  >
    <LayoutDefaultHeaderBar
      ref="headerBarRef"
      class="layout-default-header__bar"
      :class="{ 'layout-default-header__bar--search': isSearchPanelOpen }"
      :sticky="isSticky"
    />

    <div v-if="isSearchPanelOpen" class="layout-default-header__search-panel">
      <LayoutDefaultHeaderSearchPanel :search-query="currentSearchQuery" :nav-tree="navTree" />
    </div>

    <template v-if="navTree?.length">
      <LayoutDefaultHeaderNavBar class="layout-default-header__nav" :nav-tree="navTree" />

      <LayoutDefaultHeaderSideNav class="layout-default-header__side-nav" :nav-tree="navTree" />
    </template>
  </LayoutStickyBar>
</template>

<style lang="scss">
.layout-default-header {
  position: relative;
  z-index: 10;

  &__bar {
    position: relative;
    z-index: 2;

    &--search {
      background: none;
    }
  }

  &__search-panel {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
  }

  &__side-nav {
    z-index: 2;
  }
}
</style>

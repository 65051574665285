import { type OdsBreadcrumbItem } from '@onliz/design-system-vue';
import { helpers, routesNames } from '@typed-router';
import { useI18n } from 'vue-i18n';

import { useCategoryTree } from '~/composables/catalog';

const lastVisitedCategorySlug = ref<string | undefined>();

export interface BreadcrumbParameters {
  label: string;
  mainCategory?: string;
}

export default async function useBreadcrumb(parameters: Ref<BreadcrumbParameters | undefined>) {
  const { t } = useI18n({ useScope: 'global' });
  const { currentRoute } = useRouter();
  const { data: categories } = await useCategoryTree();

  const getCategoryBreadcrumbItems = (categorySlug: string): OdsBreadcrumbItem[] => {
    if (!categories.value) {
      return [];
    }

    const category = categories.value.dict[categorySlug];
    const slugs = category.parents.length > 0 ? [...category.parents, categorySlug] : [categorySlug];

    return slugs.map((slug, index) => {
      const slugArray = slugs.slice(0, index + 1);

      return {
        label: categories.value?.dict[slug]?.label ?? slug,
        to: helpers.route({
          name: routesNames.catSlug,
          params: { slug: slugArray },
        }),
      };
    });
  };

  const items = computed<OdsBreadcrumbItem[]>(() => {
    const breadcrumbItems: OdsBreadcrumbItem[] = [
      {
        label: t('global.breadcrumb.home'),
        title: t('global.breadcrumb.home'),
        to: '/',
      },
    ];

    switch (currentRoute.value.name) {
      case routesNames.slug: {
        if (parameters.value?.label) {
          breadcrumbItems.push({
            label: parameters.value.label,
            title: parameters.value.label,
            to: helpers.route({
              name: routesNames.slug,
              params: currentRoute.value.params,
            }),
          });
        }

        break;
      }

      case routesNames.pSearch: {
        const query = currentRoute.value.query.query?.toString() ?? '';

        breadcrumbItems.push({
          label: query ? t('global.breadcrumb.searchForWithQuery', { query }) : t('global.breadcrumb.searchFor'),
        });

        break;
      }

      case routesNames.catSlug: {
        const slugs = currentRoute.value.params.slug;

        lastVisitedCategorySlug.value = slugs.at(-1);

        if (lastVisitedCategorySlug.value) {
          breadcrumbItems.push(...getCategoryBreadcrumbItems(lastVisitedCategorySlug.value));
        }

        break;
      }

      case routesNames.productSlugSkuProductId: {
        if (parameters.value?.label && parameters.value.mainCategory) {
          const categorySlug = lastVisitedCategorySlug.value ?? parameters.value.mainCategory;

          breadcrumbItems.push(...getCategoryBreadcrumbItems(categorySlug), {
            label: parameters.value.label,
            title: parameters.value.label,
            to: helpers.route({
              name: routesNames.productSlugSkuProductId,
              params: currentRoute.value.params,
            }),
          });
        }

        break;
      }
    }

    return breadcrumbItems;
  });

  return { items };
}

import { helpers } from '@typed-router';
import type { RouteLocationRaw } from 'vue-router';

import type { NavigationListItemIconName } from '~/composables/useCmsNavigation';
import type { GetElementType } from '~/lib/strapi-utils';
import { filterMaybeList } from '~/lib/strapi-utils';
import type { GetNavigationQuery, Maybe } from '~/types/graphql';
import type { CategoryCode, CategoryTreeItem } from '~/utils/api/catalog/categories';
import type { NavItemConfig } from '~/utils/MainNavUtils';

export type StrapiNavItem = NonNullable<GetElementType<NonNullable<GetNavigationQuery['renderNavigation']>>>;

export function mapCmsNavItemToNavItemConfig({
  id,
  icon,
  title: label,
  path,
  externalPath,
}: StrapiNavItem): NavItemConfig {
  const navItem: NavItemConfig = { id, label };

  if (path) {
    navItem.to = `/${path.startsWith('/') ? path.slice(1) : path}`;
  } else if (externalPath) {
    navItem.href = externalPath;
  }

  if (icon) {
    navItem.icon = { type: 'strapi-icon', name: icon as NavigationListItemIconName };
  }

  return navItem;
}

export function sortMayBeList<T extends { order: number }>(input: Maybe<T>[]): T[] {
  return filterMaybeList(input).sort(({ order: a }, { order: b }) => {
    if (a < b) {
      return -1;
    } else if (a > b) {
      return 1;
    }

    return 0;
  });
}

function getCategoryByCodeOrFail(index: Map<CategoryCode, CategoryTreeItem>, code: CategoryCode): CategoryTreeItem {
  const category = index.get(code);

  if (category === undefined) {
    throw new Error('Category not found.');
  }

  return category;
}

export function getParentCategoryList(
  categoryTreeItem: CategoryTreeItem,
  index: Map<CategoryCode, CategoryTreeItem>,
): CategoryTreeItem[] {
  const parents = [];

  let parentCode: CategoryCode | undefined = categoryTreeItem.parentCode;

  while (parentCode !== undefined) {
    const category = getCategoryByCodeOrFail(index, parentCode);

    parents.unshift(category);

    parentCode = category.parentCode;
  }

  return parents;
}

export function getCategoryRouteLocationRaw(
  categoryItem: CategoryTreeItem,
  index: Map<CategoryCode, CategoryTreeItem>,
): RouteLocationRaw {
  const parents = getParentCategoryList(categoryItem, index);

  switch (parents.length) {
    case 0: {
      return helpers.route({
        name: 'cat-slug',
        params: { slug: [categoryItem.slug] },
      });
    }
    case 1: {
      return helpers.route({
        name: 'cat-slug',
        params: {
          slug: [parents[0].slug, categoryItem.slug],
        },
      });
    }
    case 2: {
      return helpers.route({
        name: 'cat-slug',
        params: {
          slug: [parents[0].slug, parents[1].slug, categoryItem.slug],
        },
      });
    }
    default: {
      throw new Error('Unable to create category link');
    }
  }
}

export function mapCategoryTreeItemToNavItemConfig(
  categoryTreeItem: CategoryTreeItem,
  index: Map<CategoryCode, CategoryTreeItem>,
): NavItemConfig {
  const { label, code: id, children } = categoryTreeItem;

  return {
    id,
    label,
    to: getCategoryRouteLocationRaw(categoryTreeItem, index),
    children: children.reduce<NavItemConfig[]>((acc, node) => {
      if (node.productCount) {
        acc.push(mapCategoryTreeItemToNavItemConfig(node, index));
      }

      return acc;
    }, []),
  };
}

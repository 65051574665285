<script setup lang="ts">
import { OdsColorScheme } from '@onliz/design-system-vue';
import { useAttrs } from 'vue';

defineProps<{ isFirstActive?: boolean }>();

const attrs = useAttrs();
</script>

<template>
  <OdsColorScheme v-if="isFirstActive" scheme="primary" skip-root-tag>
    <div v-bind="attrs" class="layout-default-header-nav-bar-dropdown-menu-layer">
      <slot></slot>
    </div>
  </OdsColorScheme>

  <template v-else>
    <div v-bind="attrs" class="layout-default-header-nav-bar-dropdown-menu-layer">
      <slot></slot>
    </div>
  </template>
</template>

<template>
  <Transition name="scale-up-">
    <slot></slot>
  </Transition>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.scale-up {
  &--enter-active,
  &--leave-active {
    transition: transform 300ms ods.$ease-out-circ;
  }

  &--enter-from,
  &--leave-to {
    transform: scale(0);
  }
}
</style>

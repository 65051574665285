import { useCachedAsyncQuery } from '~/composables/useCachedAsyncQuery';
import { CACHE_TTL_S_12_HOURS, CACHE_TTL_S_15_MIN, GLOBAL_TAG } from '~/lib/cache-utils';
import GetGlobalSettingsGQL from '~/queries/global-settings.gql';
import type { GetGlobalSettingsQuery } from '~/types/graphql';

export async function useCmsGlobalSettings() {
  const { data: globalSettings } = await useCachedAsyncQuery<GetGlobalSettingsQuery>(
    'cms-global-settings',
    GetGlobalSettingsGQL,
    {},
    {
      deep: false,
      serverCacheTags: [GLOBAL_TAG],
      serverMaxAge: CACHE_TTL_S_12_HOURS,
      clientMaxAge: CACHE_TTL_S_15_MIN,
    },
  );

  return { globalSettings };
}

// @ts-nocheck
// eslint-disable
// ---------------------------------------------------
// 🚗🚦 Generated by nuxt-typed-router. Do not modify !
// ---------------------------------------------------

/**
 * Exhaustive list of all the available route names in the app
 * */
export type RoutesNamesList =
  | 'slug'
  | 'basket'
  | 'cat-slug'
  | 'contact'
  | 'index'
  | 'lp-slug'
  | 'p-search'
  | 'product-slug-sku-productId'
  | 'nos-produits'
  | 'billing-index-information'
  | 'billing-index-signatory'
  | 'billing'
  | 'blog'
  | 'customer-index-addresses'
  | 'customer-index-favorites'
  | 'customer-index-account-organization'
  | 'customer-index-account-profile'
  | 'customer-index-account-users'
  | 'customer-index-discussion-archived'
  | 'customer-index-discussion-list'
  | 'customer-index-orders-active'
  | 'customer-index-orders-archived'
  | 'customer-index-orders-processing'
  | 'customer-index-orders-signing'
  | 'customer'
  | 'login'
  | 'signup'
  | 'billing-loading-iframe'
  | 'billing-signing'
  | 'content-informations'
  | 'order-confirmation'
  | 'product-search'
  | 'blog-article-article'
  | 'blog-cat-cat'
  | 'content-faq-slug'
  | 'content-vendeurs-slug'
  | 'customer-discussion-id'
  | 'order-item-id'
  | 'content-slug'
  | 'content-qsm';

/**
 * Routes params are only required for the exact targeted route name,
 * vue-router behaviour allow to navigate between children routes without the need to provide all the params every time.
 * So we can't enforce params when navigating between routes, only a `[xxx].vue` page will have required params in the type definition
 *
 * */
export type RoutesParamsRecord = {
  slug: {
    slug: string | number;
  };
  basket: never;
  'cat-slug': {
    slug: (string | number)[];
  };
  contact: never;
  index: never;
  'lp-slug': {
    slug: string | number;
  };
  'p-search': never;
  'product-slug-sku-productId': {
    slug: string | number;
    productId: string | number;
  };
  'nos-produits': never;
  'billing-index-information': never;
  'billing-index-signatory': never;
  billing: never;
  blog: never;
  'customer-index-addresses': never;
  'customer-index-favorites': never;
  'customer-index-account-organization': never;
  'customer-index-account-profile': never;
  'customer-index-account-users': never;
  'customer-index-discussion-archived': never;
  'customer-index-discussion-list': never;
  'customer-index-orders-active': never;
  'customer-index-orders-archived': never;
  'customer-index-orders-processing': never;
  'customer-index-orders-signing': never;
  customer: never;
  login: never;
  signup: never;
  'billing-loading-iframe': never;
  'billing-signing': never;
  'content-informations': never;
  'order-confirmation': never;
  'product-search': never;
  'blog-article-article': {
    article?: string | number;
  };
  'blog-cat-cat': {
    cat?: string | number;
  };
  'content-faq-slug': {
    slug?: string | number;
  };
  'content-vendeurs-slug': {
    slug?: string | number;
  };
  'customer-discussion-id': {
    id?: string | number;
  };
  'order-item-id': {
    id?: string | number;
  };
  'content-slug': {
    slug?: string | number;
  };
  'content-qsm': never;
};

/**
 * Record resolved used for resolved routes
 *
 * */
export type RoutesParamsRecordResolved = {
  slug: {
    slug: string;
  };
  basket: never;
  'cat-slug': {
    slug: string[];
  };
  contact: never;
  index: never;
  'lp-slug': {
    slug: string;
  };
  'p-search': never;
  'product-slug-sku-productId': {
    slug: string;
    productId: string;
  };
  'nos-produits': never;
  'billing-index-information': never;
  'billing-index-signatory': never;
  billing: never;
  blog: never;
  'customer-index-addresses': never;
  'customer-index-favorites': never;
  'customer-index-account-organization': never;
  'customer-index-account-profile': never;
  'customer-index-account-users': never;
  'customer-index-discussion-archived': never;
  'customer-index-discussion-list': never;
  'customer-index-orders-active': never;
  'customer-index-orders-archived': never;
  'customer-index-orders-processing': never;
  'customer-index-orders-signing': never;
  customer: never;
  login: never;
  signup: never;
  'billing-loading-iframe': never;
  'billing-signing': never;
  'content-informations': never;
  'order-confirmation': never;
  'product-search': never;
  'blog-article-article': {
    article?: string;
  };
  'blog-cat-cat': {
    cat?: string;
  };
  'content-faq-slug': {
    slug?: string;
  };
  'content-vendeurs-slug': {
    slug?: string;
  };
  'customer-discussion-id': {
    id?: string;
  };
  'order-item-id': {
    id?: string;
  };
  'content-slug': {
    slug?: string;
  };
  'content-qsm': never;
};

/**
 * Discriminated union that will allow to infer params based on route name
 * It's used for programmatic navigation like router.push or <NuxtLink/>
 * */
export type RoutesNamedLocations =
  | {
      name: 'slug';
      params: {
        slug: string | number;
      };
    }
  | { name: 'basket' }
  | {
      name: 'cat-slug';
      params: {
        slug: (string | number)[];
      };
    }
  | { name: 'contact' }
  | { name: 'index' }
  | {
      name: 'lp-slug';
      params: {
        slug: string | number;
      };
    }
  | { name: 'p-search' }
  | {
      name: 'product-slug-sku-productId';
      params: {
        slug: string | number;
        productId: string | number;
      };
    }
  | { name: 'nos-produits' }
  | { name: 'billing-index-information' }
  | { name: 'billing-index-signatory' }
  | { name: 'billing' }
  | { name: 'blog' }
  | { name: 'customer-index-addresses' }
  | { name: 'customer-index-favorites' }
  | { name: 'customer-index-account-organization' }
  | { name: 'customer-index-account-profile' }
  | { name: 'customer-index-account-users' }
  | { name: 'customer-index-discussion-archived' }
  | { name: 'customer-index-discussion-list' }
  | { name: 'customer-index-orders-active' }
  | { name: 'customer-index-orders-archived' }
  | { name: 'customer-index-orders-processing' }
  | { name: 'customer-index-orders-signing' }
  | { name: 'customer' }
  | { name: 'login' }
  | { name: 'signup' }
  | { name: 'billing-loading-iframe' }
  | { name: 'billing-signing' }
  | { name: 'content-informations' }
  | { name: 'order-confirmation' }
  | { name: 'product-search' }
  | {
      name: 'blog-article-article';
      params?: {
        article?: string | number;
      };
    }
  | {
      name: 'blog-cat-cat';
      params?: {
        cat?: string | number;
      };
    }
  | {
      name: 'content-faq-slug';
      params?: {
        slug?: string | number;
      };
    }
  | {
      name: 'content-vendeurs-slug';
      params?: {
        slug?: string | number;
      };
    }
  | {
      name: 'customer-discussion-id';
      params?: {
        id?: string | number;
      };
    }
  | {
      name: 'order-item-id';
      params?: {
        id?: string | number;
      };
    }
  | {
      name: 'content-slug';
      params?: {
        slug?: string | number;
      };
    }
  | { name: 'content-qsm' };

/**
 * Type returned by a resolved Route that will allow to type guard the route name.
 * By default the params are unknown
 * */
export type RoutesNamedLocationsResolved = {
  name: RoutesNamesList;
  params: unknown;
} & (
  | {
      name: 'slug';
      params: {
        slug: string;
      };
    }
  | { name: 'basket' }
  | {
      name: 'cat-slug';
      params: {
        slug: string[];
      };
    }
  | { name: 'contact' }
  | { name: 'index' }
  | {
      name: 'lp-slug';
      params: {
        slug: string;
      };
    }
  | { name: 'p-search' }
  | {
      name: 'product-slug-sku-productId';
      params: {
        slug: string;
        productId: string;
      };
    }
  | { name: 'nos-produits' }
  | { name: 'billing-index-information' }
  | { name: 'billing-index-signatory' }
  | { name: 'billing' }
  | { name: 'blog' }
  | { name: 'customer-index-addresses' }
  | { name: 'customer-index-favorites' }
  | { name: 'customer-index-account-organization' }
  | { name: 'customer-index-account-profile' }
  | { name: 'customer-index-account-users' }
  | { name: 'customer-index-discussion-archived' }
  | { name: 'customer-index-discussion-list' }
  | { name: 'customer-index-orders-active' }
  | { name: 'customer-index-orders-archived' }
  | { name: 'customer-index-orders-processing' }
  | { name: 'customer-index-orders-signing' }
  | { name: 'customer' }
  | { name: 'login' }
  | { name: 'signup' }
  | { name: 'billing-loading-iframe' }
  | { name: 'billing-signing' }
  | { name: 'content-informations' }
  | { name: 'order-confirmation' }
  | { name: 'product-search' }
  | {
      name: 'blog-article-article';
      params: {
        article?: string;
      };
    }
  | {
      name: 'blog-cat-cat';
      params: {
        cat?: string;
      };
    }
  | {
      name: 'content-faq-slug';
      params: {
        slug?: string;
      };
    }
  | {
      name: 'content-vendeurs-slug';
      params: {
        slug?: string;
      };
    }
  | {
      name: 'customer-discussion-id';
      params: {
        id?: string;
      };
    }
  | {
      name: 'order-item-id';
      params: {
        id?: string;
      };
    }
  | {
      name: 'content-slug';
      params: {
        slug?: string;
      };
    }
  | { name: 'content-qsm' }
);

export type RoutesNamesListRecord = {
  slug: 'slug';
  basket: 'basket';
  catSlug: 'cat-slug';
  contact: 'contact';
  index: 'index';
  lpSlug: 'lp-slug';
  pSearch: 'p-search';
  productSlugSkuProductId: 'product-slug-sku-productId';
  nosProduits: 'nos-produits';
  billingIndexInformation: 'billing-index-information';
  billingIndexSignatory: 'billing-index-signatory';
  billing: 'billing';
  blog: 'blog';
  customerIndexAddresses: 'customer-index-addresses';
  customerIndexFavorites: 'customer-index-favorites';
  customerIndexAccountOrganization: 'customer-index-account-organization';
  customerIndexAccountProfile: 'customer-index-account-profile';
  customerIndexAccountUsers: 'customer-index-account-users';
  customerIndexDiscussionArchived: 'customer-index-discussion-archived';
  customerIndexDiscussionList: 'customer-index-discussion-list';
  customerIndexOrdersActive: 'customer-index-orders-active';
  customerIndexOrdersArchived: 'customer-index-orders-archived';
  customerIndexOrdersProcessing: 'customer-index-orders-processing';
  customerIndexOrdersSigning: 'customer-index-orders-signing';
  customer: 'customer';
  login: 'login';
  signup: 'signup';
  billingLoadingIframe: 'billing-loading-iframe';
  billingSigning: 'billing-signing';
  contentInformations: 'content-informations';
  orderConfirmation: 'order-confirmation';
  productSearch: 'product-search';
  blogArticleArticle: 'blog-article-article';
  blogCatCat: 'blog-cat-cat';
  contentFaqSlug: 'content-faq-slug';
  contentVendeursSlug: 'content-vendeurs-slug';
  customerDiscussionId: 'customer-discussion-id';
  orderItemId: 'order-item-id';
  contentSlug: 'content-slug';
  contentQsm: 'content-qsm';
};

export const routesNames = {
  slug: 'slug' as const,
  basket: 'basket' as const,
  catSlug: 'cat-slug' as const,
  contact: 'contact' as const,
  index: 'index' as const,
  lpSlug: 'lp-slug' as const,
  pSearch: 'p-search' as const,
  productSlugSkuProductId: 'product-slug-sku-productId' as const,
  nosProduits: 'nos-produits' as const,
  billingIndexInformation: 'billing-index-information' as const,
  billingIndexSignatory: 'billing-index-signatory' as const,
  billing: 'billing' as const,
  blog: 'blog' as const,
  customerIndexAddresses: 'customer-index-addresses' as const,
  customerIndexFavorites: 'customer-index-favorites' as const,
  customerIndexAccountOrganization: 'customer-index-account-organization' as const,
  customerIndexAccountProfile: 'customer-index-account-profile' as const,
  customerIndexAccountUsers: 'customer-index-account-users' as const,
  customerIndexDiscussionArchived: 'customer-index-discussion-archived' as const,
  customerIndexDiscussionList: 'customer-index-discussion-list' as const,
  customerIndexOrdersActive: 'customer-index-orders-active' as const,
  customerIndexOrdersArchived: 'customer-index-orders-archived' as const,
  customerIndexOrdersProcessing: 'customer-index-orders-processing' as const,
  customerIndexOrdersSigning: 'customer-index-orders-signing' as const,
  customer: 'customer' as const,
  login: 'login' as const,
  signup: 'signup' as const,
  billingLoadingIframe: 'billing-loading-iframe' as const,
  billingSigning: 'billing-signing' as const,
  contentInformations: 'content-informations' as const,
  orderConfirmation: 'order-confirmation' as const,
  productSearch: 'product-search' as const,
  blogArticleArticle: 'blog-article-article' as const,
  blogCatCat: 'blog-cat-cat' as const,
  contentFaqSlug: 'content-faq-slug' as const,
  contentVendeursSlug: 'content-vendeurs-slug' as const,
  customerDiscussionId: 'customer-discussion-id' as const,
  orderItemId: 'order-item-id' as const,
  contentSlug: 'content-slug' as const,
  contentQsm: 'content-qsm' as const,
};

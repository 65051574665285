import { useResizeObserver } from '@vueuse/core';
import { type Ref } from 'vue';

import { toPx } from '~/utils/CssUtils';

export default function useSetHeaderHeight(headerRef: Ref<HTMLElement | undefined>) {
  useResizeObserver(headerRef, (entries) => {
    const [entry] = entries;

    const { y, height } = entry.contentRect;
    const headerBarHeight = Math.ceil(y + height);

    useHead({
      style: [
        {
          id: 'header-height',
          innerHTML: `:root { --header-height: ${toPx(headerBarHeight)}; }`,
        },
      ],
    });
  });
}

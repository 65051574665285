import { type OdsBreadcrumbItem } from '@onliz/design-system-vue';

import { callWithNuxt } from '#app';

interface BreadcrumJsonLdItem {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '@type': 'ListItem';
  position: number;
  item: string;
  name: string;
}

export default function useBreadcrumbJsonLd(items: Ref<OdsBreadcrumbItem[]>): void {
  const itemsForBreadcrumb = ref<BreadcrumJsonLdItem[]>([]);
  const app = useNuxtApp();
  const {
    public: { siteUrl },
  } = useRuntimeConfig();

  watch(
    () => items.value,
    async (items) => {
      itemsForBreadcrumb.value = await Promise.all(
        items.map<Promise<BreadcrumJsonLdItem>>(async (item, position) => {
          let url = '';
          const to = item.to;

          if (to) {
            const path = typeof to === 'string' ? to : await callWithNuxt(app, () => useLink({ to }).href.value);

            url = `${siteUrl}${path}`;
          } else if (item.href) {
            url = item.href.includes('http') ? item.href : `${siteUrl}${item.href}`;
          }

          return {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            '@type': 'ListItem',
            position: position + 1,
            item: url,
            name: item.title ?? item.label,
          };
        }),
      );
    },
    {
      immediate: true,
    },
  );

  useJsonld('breadcrumb', () => ({
    /* eslint-disable @typescript-eslint/naming-convention*/
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    /* eslint-enable @typescript-eslint/naming-convention*/
    itemListElement: itemsForBreadcrumb.value,
  }));
}

<script lang="ts" setup>
import { OdsClickableElement, OdsIcon } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import type { RouteLocationRaw } from 'vue-router';

import { ImageSizeMap } from '#imports';
import type { MarketPlaceSearchPanelItem } from '~/utils/MarketPlaceSearchPanelUtils';

const props = defineProps<{
  product: MarketPlaceSearchPanelItem;
  searchQuery: string;
  count: number;
  index: number;
}>();

const to = computed<RouteLocationRaw>(() => {
  return helpers.route({
    name: 'product-slug-sku-productId',
    params: {
      slug: props.product.slug,
      productId: props.product.id,
    },
  });
});
const { trackSearchEvent } = useTracking();
const onProductClick = () =>
  trackSearchEvent('click_search_results', props.searchQuery, props.count, props.index, props.product.slug);
</script>

<template>
  <div
    class="layout-default-header-search-panel-product-item"
    :class="{ 'layout-default-header-search-panel-product-item--hidden': product.hidden }"
  >
    <div v-if="product.image" class="layout-default-header-search-panel-product-item__image">
      <ImageElement
        :src="product.image"
        fit="contain"
        class="layout-default-header-search-panel-product-item__image-el"
        :modifiers="{ container: 'product' }"
        :width="ImageSizeMap.productThumbnail.width"
        :height="ImageSizeMap.productThumbnail.height"
        :alt="product.title"
      />
    </div>

    <div class="layout-default-header-search-panel-product-item__content">
      <OdsClickableElement class="layout-default-header-search-panel-product-item__title" :to @click="onProductClick">
        <!-- eslint-disable-next-line  -->
        <span v-html="product._highlightResult.title.value"></span>

        <OdsIcon
          v-if="product.hidden"
          class="layout-default-header-search-panel-product-item__hidden-icon"
          name="visibility-off"
        />
      </OdsClickableElement>

      <PricingRent :value="product.minPrice" size="small" start-from />

      <ProductShippedBy
        class="layout-default-header-search-panel-product-item__shipped-by"
        :supplier-name="product.supplier.name"
      />
    </div>
  </div>
</template>

<style lang="scss">
@use '~/assets/scss/mixins-utils' as mixins;

.layout-default-header-search-panel-product-item {
  display: flex;
  gap: var(--spacing--s);
  position: relative;
  border-radius: var(--radius--m);

  &--hidden {
    background-color: var(--semantic--warning-light);
  }

  &__hidden-icon {
    margin-left: var(--spacing--xs);
  }

  &__title {
    @include mixins.clickable-area;

    line-height: 1.1;

    &:hover {
      text-decoration: underline;
    }

    mark {
      background: none;
      color: currentcolor;
      font-weight: bold;
    }
  }

  &__image {
    flex: 0 0 60px;

    &-el {
      overflow: hidden;
      display: block;
    }
  }

  &__content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing--xs);
  }

  &__shipped-by {
    font-size: var(--extra-small--font-size);

    a {
      position: relative;
      z-index: 2;
    }
  }
}
</style>

<i18n lang="json" locale="fr-FR">
{
  "logo": {
    "title": "@:{'global.action.goToHomepage'}"
  },
  "headings": {
    "social": "Suivez-nous sur les réseaux",
    "sav": "SAV basé en France",
    "about": "À propos d’Onliz",
    "customer": "Informations clients"
  },
  "support": {
    "phone": "@:{'global.support.phone.international'}",
    "openingHours": "@:{'global.support.openingHours'}"
  },
  "supplier": {
    "title": "Vous êtes fabricant ou fournisseur ?",
    "description": "Vous voulez accroitre votre visibilité et vendre sur la place de marché onliz ?",
    "contact": "Contactez-nous sur {0} !",
    "mail": "@:{'global.support.supplierEmail'}"
  }
}
</i18n>

<script setup lang="ts">
import OnlizLogo from '@onliz/design-system-assets/logo/logo-onliz-baseline.svg';
import { OdsClickableElement, OdsColorScheme, OdsIcon } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import { formatPhoneNumber } from '#imports';
import { assembleCacheKey, CACHE_TTL_S_12_HOURS, CACHE_TTL_S_15_MIN, NAVIGATION_TAG } from '~/lib/cache-utils';
import GetNavigationGQL from '~/queries/navigation.gql';
import type { GetNavigationQuery, NavigationItem } from '~/types/graphql';
import { customerServiceSchedules } from '~/utils/CustomerServiceUtils';

const { t } = useI18n();

const phoneNumberLabel = formatPhoneNumber(t('support.phone'), ' ');

const [{ data: footerNavigationAbout }, { data: footerNavigationCustomer }, { data: footerNavigationLegals }] =
  await Promise.all(
    [CmsFooterNavigationSlug.about, CmsFooterNavigationSlug.customers, CmsFooterNavigationSlug.legals].map((slug) =>
      useCachedAsyncQuery<GetNavigationQuery>(
        assembleCacheKey('nav', slug),
        GetNavigationGQL,
        { slug },
        {
          deep: false,
          serverMaxAge: CACHE_TTL_S_12_HOURS,
          clientMaxAge: CACHE_TTL_S_15_MIN,
          serverCacheTags: [NAVIGATION_TAG],
        },
      ),
    ),
  );

const footerNavigationAboutItems = computed<NavigationItem[]>(() => {
  return (footerNavigationAbout.value?.renderNavigation as NavigationItem[]) ?? [];
});

const footerNavigationCustomerItems = computed<NavigationItem[]>(() => {
  return (footerNavigationCustomer.value?.renderNavigation as NavigationItem[]) ?? [];
});

const footerNavigationLegalsItems = computed<NavigationItem[]>(() => {
  return (footerNavigationLegals.value?.renderNavigation as NavigationItem[]) ?? [];
});
</script>

<template>
  <footer class="layout-default-footer">
    <LayoutDefaultFooterReinssurance class="layout-default-footer__reinssurance" />

    <LayoutDefaultFooterSeo class="layout-default-footer__seo" />

    <OdsColorScheme scheme="primary">
      <div class="layout-default-footer__bar">
        <div class="layout-default-footer__section layout-default-footer__section--logo">
          <OdsClickableElement class="layout-default-footer__logo-link" to="/" :title="t('logo.title')">
            <OnlizLogo />
          </OdsClickableElement>
        </div>

        <LayoutDefaultFooterSection
          class="layout-default-footer__section layout-default-footer__section--social"
          :title="t('headings.social')"
        >
          <LayoutFooterSocial />
        </LayoutDefaultFooterSection>

        <LayoutDefaultFooterSection
          class="layout-default-footer__section layout-default-footer__section--sav"
          :title="t('headings.sav')"
        >
          <div class="layout-default-footer__phone">
            <OdsIcon name="call" aria-hidden="true" />

            <a :href="`tel:${t('support.phone')}`" class="layout-default-footer__phone-number">{{
              phoneNumberLabel
            }}</a>
          </div>

          <p class="layout-default-footer__opening">
            {{ t('support.openingHours', customerServiceSchedules) }}
          </p>
        </LayoutDefaultFooterSection>

        <LayoutDefaultFooterSection
          v-if="footerNavigationAboutItems.length > 0"
          class="layout-default-footer__section layout-default-footer__section--about"
          :title="t('headings.about')"
        >
          <NavigationList :items="footerNavigationAboutItems" />
        </LayoutDefaultFooterSection>

        <LayoutDefaultFooterSection
          v-if="footerNavigationCustomerItems.length > 0"
          class="layout-default-footer__section layout-default-footer__section--customer"
          :title="t('headings.customer')"
        >
          <NavigationList :items="footerNavigationCustomerItems" />
        </LayoutDefaultFooterSection>

        <OdsColorScheme
          scheme="dark"
          class="layout-default-footer__section layout-default-footer__section--supplier layout-default-footer__supplier"
        >
          <div class="layout-default-footer__supplier-title">
            {{ t('supplier.title') }}
          </div>

          <p class="layout-default-footer__supplier-desc">
            {{ t('supplier.description') }}
            <br />

            <i18n-t class="layout-default-footer__supplier-description" keypath="supplier.contact" tag="span">
              <a :href="`mailto:${t('supplier.mail')}`">{{ t('supplier.mail') }}</a>
            </i18n-t>
          </p>
        </OdsColorScheme>
      </div>
    </OdsColorScheme>

    <LayoutSection scheme="primary" class="layout-default-footer__legals">
      <LayoutFooterLegals v-if="footerNavigationLegalsItems.length > 0" :items="footerNavigationLegalsItems" />
    </LayoutSection>
  </footer>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-default-footer {
  $layout-default-footer-border: 2px solid var(--scheme--border);

  overflow: hidden;

  &__seo {
    border-bottom: $layout-default-footer-border;
  }

  &__bar {
    font-size: var(--small--font-size);
    padding: var(--spacing--m) max(var(--layout-container-gutter), env(safe-area-inset-right)) var(--spacing--m)
      max(var(--layout-container-gutter), env(safe-area-inset-left));
    display: grid;
    width: 100%;
    gap: var(--spacing--m);
    grid:
      'logo' auto
      'social' auto
      'sav' auto
      'about' auto
      'customer' auto
      'supplier' auto
      / 1fr;

    @media screen and (min-width: ods.$breakpoint-xs) {
      grid:
        'logo logo logo' auto
        'social about customer' auto
        'sav about customer' auto
        'supplier supplier supplier' auto
        / 1fr 1fr 1fr;
    }

    @media screen and (min-width: ods.$breakpoint-l) {
      padding-top: var(--spacing--l);
      padding-bottom: var(--spacing--l);
      grid:
        'logo social about customer' auto
        'logo sav about customer' auto
        'supplier supplier supplier supplier' auto
        / 2fr 3fr 2fr 2fr;
    }

    @media screen and (min-width: ods.$breakpoint-xl) {
      padding-right: 0;
      grid:
        'logo social about customer supplier' auto
        'logo sav about customer supplier' auto
        / 2fr 3fr 2fr 2fr 3fr;
    }

    @media screen and (min-width: $global-layout-container-max-width) {
      padding-right: max(var(--layout-container-gutter), env(safe-area-inset-right));
    }
  }

  &__logo-link {
    width: 100%;
    display: block;
    max-width: 120px;

    @media screen and (min-width: ods.$breakpoint-l) {
      max-width: 160px;
    }
  }

  &__section {
    &--logo {
      grid-area: logo;
    }

    &--sav {
      grid-area: sav;
    }

    &--social {
      grid-area: social;
    }

    &--about {
      grid-area: about;
    }

    &--customer {
      grid-area: customer;
    }

    &--supplier {
      grid-area: supplier;
    }
  }

  &__phone {
    color: var(--scheme--color-emphasis);
    display: flex;
    align-items: center;
    gap: var(--spacing--xs);
  }

  &__phone-number {
    font-weight: bold;
    border-bottom: 1px solid currentcolor;
    display: block;

    &:hover {
      color: var(--scheme--color);
    }
  }

  &__supplier {
    font-size: var(--small--font-size);
    border-radius: var(--radius--l);
    padding: var(--spacing--m);
    background-color: var(--semantic--warning);

    @media screen and (min-width: ods.$breakpoint-xl) {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      padding-right: max(var(--layout-container-gutter), env(safe-area-inset-right));
    }

    @media screen and (min-width: $global-layout-container-max-width) {
      border-bottom-right-radius: var(--radius--l);
      border-top-right-radius: var(--radius--l);
      padding-right: var(--spacing--m);
    }
  }

  &__supplier-title {
    @include ods.h6;
  }

  &__legals {
    border-top: $layout-default-footer-border;
  }
}
</style>

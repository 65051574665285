<i18n lang="json" locale="fr-FR">
{
  "mobileSummaryTitle": "Louez votre équipement"
}
</i18n>

<script setup lang="ts">
import { OdsColorScheme, OdsIcon, type OdsIconName } from '@onliz/design-system-vue';
import { OdsDetails } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { isMobile } = useDevice();
const isOpen = ref(!isMobile);

const summaryIconName = computed<OdsIconName>(() => {
  return isOpen.value ? 'expand-less' : 'expand-more';
});

const { globalSettings } = await useCmsGlobalSettings();

const linkListItems = computed(() => {
  return globalSettings.value?.global?.data?.attributes?.Prefooter ?? [];
});
</script>

<template>
  <OdsColorScheme scheme="primary">
    <LayoutContainer>
      <OdsDetails v-model:open="isOpen" class="layout-default-footer-seo">
        <template #summary>
          <div class="layout-default-footer-seo__summary">
            <span class="layout-default-footer-seo__summary-label">{{ t('mobileSummaryTitle') }}</span>

            <span class="layout-default-footer-seo__summary-icon">
              <OdsIcon :name="summaryIconName" />
            </span>
          </div>
        </template>

        <template #default>
          <nav class="layout-default-footer-seo__nav">
            <ul class="layout-default-footer-seo__row">
              <template v-for="(item, i) in linkListItems" :key="`layout-default-footer-seo-nav-${i}`">
                <li v-if="item" class="layout-default-footer-seo__col">
                  <span class="layout-default-footer-seo__title">{{ item.title }}</span>

                  <ul class="layout-default-footer-seo__list">
                    <li
                      v-for="(link, n) in item.links"
                      :key="`layout-default-footer-seo-nav-${i}-${n}`"
                      class="layout-default-footer-seo__item"
                    >
                      <CmsFieldLink
                        v-if="link"
                        raw-link
                        variant="link"
                        :data="link"
                        class="layout-default-footer-seo__link"
                      />
                    </li>
                  </ul>
                </li>
              </template>
            </ul>
          </nav>
        </template>
      </OdsDetails>
    </LayoutContainer>
  </OdsColorScheme>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-default-footer-seo {
  --layout-default-footer-seo--grid: 1fr;

  @media screen and (min-width: ods.$breakpoint-s) {
    & > summary {
      display: none;
    }

    .ods-expandable-area__container {
      padding: 0;
    }
  }

  &__summary {
    padding: var(--spacing--s) 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-label {
      @include ods.h6;

      margin: 0;
    }

    &-icon {
      font-size: 1.5em;
    }
  }

  &__nav {
    font-size: var(--small--font-size);
    padding: 0 0 var(--spacing--m) 0;

    @media screen and (min-width: ods.$breakpoint-s) {
      padding: var(--spacing--m) 0;
    }

    @media screen and (min-width: ods.$breakpoint-l) {
      padding: var(--spacing--l) 0;
    }
  }

  &__row {
    display: grid;
    gap: var(--spacing--m);
    grid: var(--layout-default-footer-seo--grid);

    @media screen and (min-width: ods.$breakpoint-xs) {
      --layout-default-footer-seo--grid: repeat(2, 1fr) / repeat(2, 1fr);
    }

    @media screen and (min-width: ods.$breakpoint-l) {
      --layout-default-footer-seo--grid: auto / repeat(4, 1fr);
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    display: block;
    margin: 0 0 var(--spacing--xs) 0;
  }

  &__link {
    text-decoration: underline;

    &:hover {
      color: var(--scheme--color-emphasis);
    }
  }
}
</style>

<i18n lang="json" locale="fr-FR">
{
  "marketplace": {
    "label": "Marketplace BtoB française"
  },
  "leasing": {
    "label": "Leasing avec un loyer tout inclus"
  },
  "online": {
    "label": "Souscription rapide 100% en ligne"
  },
  "sellers": {
    "label": "Vendeurs sélectionnés et agréés"
  }
}
</i18n>

<script setup lang="ts">
import { OdsColorScheme } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import LeasingIcon from '~/assets/icons/footer/leasing.svg?component';
import MarketplaceIcon from '~/assets/icons/footer/marketplace.svg?component';
import OnlineIcon from '~/assets/icons/footer/online.svg?component';
import SellersIcon from '~/assets/icons/footer/sellers.svg?component';

const { t } = useI18n();

const FooterReinssuranceItems: {
  icon: Component;
  label: string;
  slug: string;
}[] = [
  {
    icon: MarketplaceIcon as Component,
    label: t('marketplace.label'),
    slug: 'marketplace',
  },
  {
    icon: LeasingIcon as Component,
    label: t('leasing.label'),
    slug: 'leasing',
  },
  {
    icon: OnlineIcon as Component,
    label: t('online.label'),
    slug: 'online',
  },
  {
    icon: SellersIcon as Component,
    label: t('sellers.label'),
    slug: 'sellers',
  },
];
</script>

<template>
  <OdsColorScheme scheme="dark" class="layout-default-footer-reinssurance">
    <LayoutContainer>
      <ul class="layout-default-footer-reinssurance__list">
        <li
          v-for="item in FooterReinssuranceItems"
          :key="`footer-reinssurance-${item.slug}`"
          class="layout-default-footer-reinssurance__item"
        >
          <Component :is="item.icon" class="layout-default-footer-reinssurance__icon" />

          <span class="layout-default-footer-reinssurance__label">{{ item.label }}</span>
        </li>
      </ul>
    </LayoutContainer>
  </OdsColorScheme>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-default-footer-reinssurance {
  --layout-default-footer-reinssurance-padding: var(--spacing--m);
  --layout-default-footer-reinssurance-grid: auto / 1fr;

  &__list {
    display: grid;
    align-items: center;
    gap: var(--spacing--m);
    padding: var(--layout-default-footer-reinssurance-padding) 0;
    grid: var(--layout-default-footer-reinssurance-grid);
    font-size: var(--small--font-size);

    @media screen and (min-width: ods.$breakpoint-xs) {
      --layout-default-footer-reinssurance-grid: auto / repeat(2, 1fr);
    }

    @media screen and (min-width: ods.$breakpoint-m) {
      --layout-default-footer-reinssurance-grid: auto / repeat(4, 1fr);
    }

    @media screen and (min-width: ods.$breakpoint-l) {
      --layout-default-footer-reinssurance-padding: var(--spacing--l);

      font-size: var(--default-font-size);
    }
  }

  &__item {
    display: flex;
    gap: var(--spacing--s);
    flex: 1;
    align-items: center;

    @media screen and (min-width: ods.$breakpoint-m) {
      align-items: center;
    }
  }

  &__icon {
    flex: 0 0 2em;
    fill: currentcolor;
  }
}
</style>

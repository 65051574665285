<i18n lang="json" locale="fr-FR">
{
  "action": {
    "close": {
      "label": "@:{'global.action.close'}",
      "title": "@:{'action.close.label'}"
    }
  }
}
</i18n>

<script setup lang="ts">
import { OdsIcon } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import { sideMenuNavScrollElementId } from '~/utils/MainNavUtils';

const isActive = defineModel<boolean>('active');

const { t } = useI18n();
const { unlock } = useScrollLock(sideMenuNavScrollElementId);

const close = () => {
  isActive.value &&= false;
  unlock();
};
const onCloseButtonClick = () => close();
const onOverlayClick = () => close();

const containerElRef = ref<HTMLElement>();

let cleanupSwipeListener: (() => void) | undefined;

onMounted(() => {
  watch(
    isActive,
    (isActiveValue) => {
      if (isActiveValue) {
        if (!cleanupSwipeListener) {
          ({ stop: cleanupSwipeListener } = useSwipe(containerElRef, {
            onSwipeEnd(_, direction) {
              if (direction === 'left') {
                close();
              }
            },
          }));
        }
      } else if (cleanupSwipeListener) {
        cleanupSwipeListener();
        cleanupSwipeListener = undefined;
      }
    },
    { immediate: true },
  );
});
</script>

<template>
  <div class="layout-default-header-side-menu" :class="{ 'layout-default-header-side-menu--active': isActive }">
    <div class="layout-default-header-side-menu__overlay" aria-hidden="true" @click="onOverlayClick"></div>

    <div ref="containerElRef" class="layout-default-header-side-menu__container">
      <div class="layout-default-header-side-menu__header">
        <slot name="header">
          <LayoutDefaultHeaderSideMenuHeaderButton
            :title="t('action.close.title')"
            :label="t('action.close.label')"
            @click="onCloseButtonClick"
          >
            <template #prepend>
              <OdsIcon name="close" aria-hidden="true" />
            </template>
          </LayoutDefaultHeaderSideMenuHeaderButton>

          <slot name="header-append"></slot>
        </slot>
      </div>

      <div class="layout-default-header-side-menu__body">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.layout-default-header-side-menu {
  $block-selector: &;

  --layout-default-header-side-menu--gutter: var(--spacing--s);
  --layout-default-header-side-menu--start-gutter: max(
    var(--layout-default-header-side-menu--gutter),
    env(safe-area-inset-left)
  );
  --layout-default-header-side-menu--transition-duration: 250ms;

  position: fixed;
  inset: 0;
  display: grid;
  grid-template-areas: 'content';
  height: 100dvh;
  visibility: hidden;
  transition: visibility 0s var(--layout-default-header-side-menu--transition-duration) linear;

  &--active {
    visibility: visible;
    transition-duration: var(--layout-default-header-side-menu--transition-duration);
    transition-delay: 0s;
  }

  &__overlay {
    background: var(--greyscale--black);
    grid-area: content;
    opacity: 0;
    transition: opacity var(--layout-default-header-side-menu--transition-duration);

    #{$block-selector}--active & {
      opacity: 0.3;
    }
  }

  &__container {
    grid-area: content;
    z-index: 1;
    display: grid;
    grid-template-areas:
      'header'
      'body';
    grid-template-rows: min-content 1fr;
    width: calc(
      var(--layout-default-header-side-menu--start-gutter) + 16em + var(--layout-default-header-side-menu--gutter)
    );
    height: 100dvh;
    background-color: var(--scheme--background);
    box-shadow: var(--shadow--m);
    transform: translateX(-100%);
    transition: transform var(--layout-default-header-side-menu--transition-duration);

    #{$block-selector}--active & {
      transform: translateX(0);
    }
  }

  &__header {
    display: flex;
    gap: var(--spacing--s);
    align-items: center;
    padding: max(env(safe-area-inset-top), var(--spacing--s)) var(--layout-default-header-side-menu--gutter)
      var(--spacing--s) var(--layout-default-header-side-menu--start-gutter);
    border-bottom: solid 1px var(--scheme--border);
  }

  &__body {
    padding-bottom: env(safe-area-inset-bottom);
    overflow: auto;
    overscroll-behavior: contain;
  }
}
</style>

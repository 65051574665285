import { escapeRegExp } from '~/utils/RegExpUtils';

export function sanitize(input: string): string {
  return input
    .toLowerCase()
    .normalize('NFD')
    .replaceAll(/\p{Diacritic}/gu, '');
}

export function slugify(input: string, glue = '-'): string {
  const escapedGlue = escapeRegExp(glue);

  return (
    sanitize(input)
      // Remove special characters
      .replaceAll(/[^\dA-Za-z-]+/g, glue)
      // Remove multiple glue occurrences
      .replaceAll(new RegExp(`(?:${escapedGlue})+`, 'g'), glue)
      // Trim trailing glue
      .replace(new RegExp(`^(?:${escapedGlue})+|(?:${escapedGlue})+$`), '')
  );
}

export function camelToSnakeCase(input: string): string {
  return input.replaceAll(/[A-Z]|(?<=\D)\d/g, (letter) => `_${letter.toLowerCase()}`);
}

export function camelToScreamingSnakeCase(input: string) {
  return camelToSnakeCase(input).toUpperCase();
}

export function screamingSnakeToCamelCase(input: string): string {
  return input.toLowerCase().replaceAll(/_[\da-z]/g, (match) => match.slice(1).toUpperCase());
}

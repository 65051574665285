type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];

export interface TreeHelper<Node, NodeIdKey extends keyof Node> {
  idKey: NodeIdKey;
  activeNodeIdList: Set<Node[NodeIdKey]>;
  ancestorMap: Map<Node[NodeIdKey], Map<Node[NodeIdKey], number>>;
  itemMap: Map<Node[NodeIdKey], Node>;
  rootNodeIdList: Set<Node[NodeIdKey]>;
}

export class TreeHelperError extends Error {}

export class NodeNotFoundTreeHelperError extends TreeHelperError {}

function createTreeHelper<Node extends object, NodeIdKey extends keyof Node>(
  idKey: NodeIdKey,
): TreeHelper<Node, NodeIdKey> {
  return {
    idKey,
    activeNodeIdList: new Set<Node[NodeIdKey]>(),
    ancestorMap: new Map<Node[NodeIdKey], Map<Node[NodeIdKey], number>>(),
    itemMap: new Map<Node[NodeIdKey], Node>(),
    rootNodeIdList: new Set<Node[NodeIdKey]>(),
  };
}

export function addNode<Node extends object, NodeIdKey extends keyof Node>(
  th: TreeHelper<Node, NodeIdKey>,
  node: Node,
  parentNodeId?: Node[NodeIdKey],
) {
  const nodeId = node[th.idKey];

  let descendantAncestorMap = th.ancestorMap.get(nodeId);

  if (descendantAncestorMap === undefined) {
    th.ancestorMap.set(nodeId, new Map<Node[NodeIdKey], number>());

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    descendantAncestorMap = th.ancestorMap.get(nodeId)!;
  }

  // Add current node to itself and set its depth to 0
  descendantAncestorMap.set(nodeId, 0);

  // Get ancestors from parent node
  if (parentNodeId === undefined) {
    th.rootNodeIdList.add(nodeId);
  } else {
    const parentAncestorMap = th.ancestorMap.get(parentNodeId);

    if (parentAncestorMap) {
      for (const [ancestorNodeId, depth] of parentAncestorMap.entries()) {
        descendantAncestorMap.set(ancestorNodeId, depth + 1);
      }
    }
  }

  th.itemMap.set(nodeId, node);
}

export function clearActiveNodes<Node extends object, NodeIdKey extends keyof Node>(th: TreeHelper<Node, NodeIdKey>) {
  th.activeNodeIdList.clear();
}

export function setActiveNodeId<Node extends object, NodeIdKey extends keyof Node>(
  th: TreeHelper<Node, NodeIdKey>,
  nodeId: Node[NodeIdKey] | Node[NodeIdKey][] = [],
  shouldOverwriteActiveNodes = true,
  isActive = true,
) {
  if (shouldOverwriteActiveNodes) {
    clearActiveNodes(th);

    if (!isActive) {
      return;
    }
  }

  const method: keyof typeof th.activeNodeIdList = isActive ? 'add' : 'delete';
  const nodeIdList = Array.isArray(nodeId) ? nodeId : [nodeId];

  for (const nodeIdItem of nodeIdList) {
    if (!th.itemMap.has(nodeIdItem)) {
      throw new NodeNotFoundTreeHelperError(`Node ID not found (${JSON.stringify(nodeIdItem)})`);
    }

    th.activeNodeIdList[method](nodeIdItem);
  }
}

export function removeActiveNodeId<Node extends object, NodeIdKey extends keyof Node>(
  th: TreeHelper<Node, NodeIdKey>,
  nodeId: Node[NodeIdKey] | Node[NodeIdKey][] = [],
) {
  setActiveNodeId(th, nodeId, false, false);
}

export function isActiveNodeId<Node extends object, NodeIdKey extends keyof Node>(
  th: TreeHelper<Node, NodeIdKey>,
  nodeId: Node[NodeIdKey],
  isExactActive = false,
): boolean {
  if (th.activeNodeIdList.size > 0) {
    return [...th.activeNodeIdList].some((activeNodeId) => {
      const depth = th.ancestorMap.get(activeNodeId)?.get(nodeId);

      return isExactActive ? depth === 0 : depth !== undefined;
    });
  }

  return false;
}

export function createFromExistingTree<
  Node extends object,
  NodeIdKey extends keyof Node,
  NodeChildrenKey extends KeysMatching<Required<Node>, Node[]>,
>(tree: Node[], idKey: NodeIdKey, childrenKey: NodeChildrenKey): TreeHelper<Node, NodeIdKey> {
  const th = createTreeHelper<Node, NodeIdKey>(idKey);

  const processTreeNode = (node: Node, parentId?: Node[NodeIdKey]) => {
    addNode(th, node, parentId);

    const children = node[childrenKey] as Node[] | undefined;

    if (children) {
      for (const childNode of children) {
        processTreeNode(childNode, node[idKey]);
      }
    }
  };

  for (const node of tree) {
    processTreeNode(node);
  }

  return th;
}

<script setup lang="ts">
import { watchDebounced } from '@vueuse/core';

import type { MarketPlaceSearchPanelItem } from '~/utils/MarketPlaceSearchPanelUtils';

const props = defineProps<{
  products: MarketPlaceSearchPanelItem[];
  searchQuery: string;
  count: number;
}>();
const { trackSearchEvent } = useTracking();

watchDebounced(
  () => props.products,
  () => {
    if (props.searchQuery) {
      trackSearchEvent('search', props.searchQuery, props.count);
    }
  },
  {
    debounce: 1000,
  },
);
</script>

<template>
  <div class="layout-default-header-search-panel-product-list">
    <ol class="layout-default-header-search-panel-product-list__list">
      <li
        v-for="(product, index) in products"
        :key="product.id"
        class="layout-default-header-search-panel-product-list__item"
      >
        <LayoutDefaultHeaderSearchPanelProductItem :product :count :index :search-query="searchQuery.toString()" />
      </li>
    </ol>
  </div>
</template>

<style lang="scss">
.layout-default-header-search-panel-product-list {
  container: layout-default-header-search-panel-product-list / inline-size;

  &__list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: var(--spacing--m);
    padding: 0;
    margin: 0;

    @container layout-default-header-search-panel-product-list (width > 400px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @container layout-default-header-search-panel-product-list (width > 700px) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>

<i18n lang="json" locale="fr-FR">
{
  "logo": {
    "title": "@:{'global.action.goToHomepage'}"
  }
}
</i18n>

<script setup lang="ts">
import OnlizLogo from '@onliz/design-system-assets/logo/logo-onliz.svg?component';
import { OdsClickableElement } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';

import { injectOrFail } from '~/utils/ReactivityUtils';

defineProps<{
  sticky?: boolean;
}>();

const { t } = useI18n();

const isSearchPanelOpen = injectOrFail<Ref<boolean>>('isSearchPanelOpen');
</script>

<template>
  <div
    class="layout-default-header-bar-logo"
    :class="{
      'layout-default-header-bar-logo--sticky': sticky,
    }"
  >
    <LayoutDefaultHeaderBarSearchPanelCloseButton v-if="isSearchPanelOpen" />

    <LayoutDefaultHeaderBarMainNavButton v-else :sticky />

    <OdsClickableElement class="layout-default-header-bar-logo__logo" to="/" :title="t('logo.title')">
      <OnlizLogo />
    </OdsClickableElement>
  </div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;
@use '~/assets/scss/header-nav-utils' as header-nav-utils;

.layout-default-header-bar-logo {
  $layout-default-header-bar-logo: &;

  display: flex;
  gap: var(--spacing--xs);
  align-items: flex-end;

  &--sticky {
    #{$layout-default-header-bar-logo}__logo {
      display: none;

      @include header-nav-utils.breakpoint {
        display: block;
      }
    }
  }

  &__logo {
    width: 100px;
  }
}
</style>

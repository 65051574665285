import type { OdsIconName } from '@onliz/design-system-vue';
import type { RouteLocationRaw } from 'vue-router';

import type { NavigationListItemIconName } from '~/composables/useCmsNavigation';

const LinkTargetList = ['_self', '_blank'] as const;

export type LinkTarget = (typeof LinkTargetList)[number];

interface NavItemConfigAside {
  content: string;
}

export interface NavItemConfigIconImg {
  type: 'img';
  src: string;
  alt?: string;
  width?: number;
  height?: number;
}

export interface NavItemConfigIconOdsIcon {
  type: 'ods-icon';
  name: OdsIconName;
}

export interface NavItemConfigIconStrapiIcon {
  type: 'strapi-icon';
  name: NavigationListItemIconName;
}

export type NavItemConfigIcon = NavItemConfigIconImg | NavItemConfigIconOdsIcon | NavItemConfigIconStrapiIcon;

export interface NavItemConfig {
  id: number | string;
  label?: string;
  title?: string;
  icon?: NavItemConfigIcon;
  hidden?: boolean;
  href?: string;
  to?: RouteLocationRaw;
  target?: LinkTarget;
  onClick?: (event: PointerEvent) => void;
  children?: NavItemConfig[];
  aside?: NavItemConfigAside;
}

export const sideMenuNavScrollElementId = 'side-menu-nav' as const;

<i18n lang="json" locale="fr-FR">
{
  "noResults": "Nous n'avons trouvé aucun résultat pour \"{searchQuery}\"."
}
</i18n>

<script lang="ts" setup>
import { OdsButton } from '@onliz/design-system-vue';
import { useI18n } from 'vue-i18n';
import type { LocationQueryValue, RouteLocationRaw } from 'vue-router';

import MagnifyingGlass from '~/assets/icons/custom/magnifying-glass.svg?component';
import type { NavItemConfig } from '~/utils/MainNavUtils';

const props = defineProps<{
  searchQuery: string | LocationQueryValue[];
  navTree: NavItemConfig[] | null;
}>();

type SearchPanelDefaultCategories = {
  label: string;
  to?: RouteLocationRaw;
  id: string | number;
};

const categories = computed<SearchPanelDefaultCategories[]>(() => {
  const childrenNavItems = props.navTree?.[0].children?.[0].children;

  return childrenNavItems
    ? childrenNavItems.reduce<SearchPanelDefaultCategories[]>((acc, category) => {
        if (category.label && category.to) {
          acc.push({
            label: category.label,
            to: category.to,
            id: category.id,
          });
        }

        return acc;
      }, [])
    : [];
});

const { t } = useI18n();
</script>

<template>
  <div class="layout-default-header-search-panel-no-results">
    <MagnifyingGlass class="layout-default-header-search-panel-no-results__image" />

    <div class="layout-default-header-search-panel-no-results__title">
      {{ t('noResults', { searchQuery }) }}
    </div>

    <ul v-if="categories.length > 0" class="layout-default-header-search-panel-no-results__list">
      <li v-for="item in categories" :key="item.id" class="layout-default-header-search-panel-no-results__item">
        <OdsButton v-bind="item" variant="outlined" />
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-default-header-search-panel-no-results {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--spacing--l);
  gap: var(--spacing--s);
  width: 100%;

  &__title {
    @include ods.h5;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    gap: var(--spacing--xs);
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>

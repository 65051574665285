import type { AsyncData } from '#app';
import GetNavigationGQL from '~/queries/navigation.gql';
import type { GetNavigationQuery } from '~/types/graphql';

/** @deprecated Find a better way to handle dynamic icons */
export type NavigationListItemIconName = 'refurbished';

export function useCmsNavigation(slug: string) {
  return useAsyncQuery<GetNavigationQuery>(GetNavigationGQL, { slug }) as AsyncData<GetNavigationQuery, Error>;
}

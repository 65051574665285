<script setup lang="ts">
import RefurbishedIcon from 'assets/icons/custom/refurbished.svg?component';
import type { Component } from 'vue';

import type { NavigationListItemIconName } from '~/composables/useCmsNavigation';
import type { NavItemConfigIconStrapiIcon } from '~/utils/MainNavUtils';

const props = defineProps<Omit<NavItemConfigIconStrapiIcon, 'type'>>();

const componentMap: Record<NavigationListItemIconName, Component> = {
  refurbished: RefurbishedIcon,
};

const component = computed(() => componentMap[props.name]);
</script>

<template>
  <Component :is="component" class="dynamic-icon-strapi-icon" />
</template>

<style lang="scss">
.dynamic-icon-strapi-icon {
  width: var(--dynamic-icon--size);
  height: var(--dynamic-icon--size);
}
</style>

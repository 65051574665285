<i18n lang="json" locale="fr-FR">
{
  "categories": "Catégories",
  "brands": "Marques",
  "products": "Produits",
  "seeAllProducts": "@:{'global.action.seeAll'}",
  "seeAllProductsWithHits": "@:{'global.action.seeAll'} ({nbHits})"
}
</i18n>

<script setup lang="ts">
import { OdsButton, OdsColorScheme, OdsIcon } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import { useI18n } from 'vue-i18n';
import { AisConfigure, AisHits, AisInstantSearch, AisStateResults } from 'vue-instantsearch/vue3/es';
import type { LocationQueryValue } from 'vue-router';

import { type NavItemConfig } from '~/utils/MainNavUtils';
import { MarketPlaceSearchPanelAlgoliaIndex } from '~/utils/MarketPlaceSearchPanelUtils';
import { searchPanelScrollElementId } from '~/utils/SearchPanelUtils';

const props = defineProps<{
  searchQuery: LocationQueryValue[] | string;
  navTree: NavItemConfig[] | null;
}>();

const algolia = await useAlgoliaClient();
const { unlock } = useScrollLock(searchPanelScrollElementId);
const { t } = useI18n();
const HitsPerPage = 6;
const isSearchPanelOpen = injectOrFail<Ref<boolean>>('isSearchPanelOpen');
const { filters } = useSupplierProductsOnly();
const route = useRoute();

const debouncedSearchQuery = refDebounced<LocationQueryValue[] | string>(
  toRef(() => props.searchQuery),
  400,
);

const closePanel = () => {
  isSearchPanelOpen.value = false;
  unlock();
};

const seeAllProductRoute = computed(() => {
  return helpers.route({
    name: 'p-search',
    query: props.searchQuery ? { query: props.searchQuery } : undefined,
  });
});

watch(
  () => route.query,
  () => {
    closePanel();
  },
);
</script>

<template>
  <OdsColorScheme skip-root-tag>
    <LayoutContainer class="layout-default-header-search-panel">
      <AisInstantSearch
        :index-name="MarketPlaceSearchPanelAlgoliaIndex"
        :search-client="algolia"
        :future="{
          preserveSharedStateOnUnmount: true,
        }"
      >
        <AisConfigure :hits-per-page.camel="HitsPerPage" :query="debouncedSearchQuery" :filters />

        <AisStateResults class="layout-default-header-search-panel__container">
          <template #default="{ results: { hits, nbHits } }">
            <template v-if="hits.length > 0">
              <div class="layout-default-header-search-panel__navigation">
                <div class="layout-default-header-search-panel__categories">
                  <span class="layout-default-header-search-panel__header">
                    <span class="layout-default-header-search-panel__title">{{ t('categories') }}</span>
                  </span>

                  <LayoutDefaultHeaderSearchPanelCategories />
                </div>

                <div class="layout-default-header-search-panel__brands">
                  <span class="layout-default-header-search-panel__header">
                    <span class="layout-default-header-search-panel__title">{{ t('brands') }}</span>
                  </span>

                  <LayoutDefaultHeaderSearchPanelBrands />
                </div>
              </div>

              <AisHits class="layout-default-header-search-panel__products">
                <template #default="{ items }">
                  <span class="layout-default-header-search-panel__header">
                    <span class="layout-default-header-search-panel__title">{{ t('products') }}</span>

                    <OdsButton
                      v-if="nbHits > HitsPerPage"
                      variant="outlined"
                      class="layout-default-header-search-panel__see-more"
                      :label="searchQuery.length > 0 ? t('seeAllProductsWithHits', { nbHits }) : t('seeAllProducts')"
                      :to="seeAllProductRoute"
                    >
                      <template #append>
                        <OdsIcon name="arrow-forward" />
                      </template>
                    </OdsButton>
                  </span>

                  <LayoutDefaultHeaderSearchPanelProductList
                    :products="items"
                    :search-query="debouncedSearchQuery.toString()"
                    :count="nbHits"
                  />
                </template>
              </AisHits>
            </template>

            <LayoutDefaultHeaderSearchPanelNoResults v-else :nav-tree :search-query="debouncedSearchQuery" />
          </template>
        </AisStateResults>
      </AisInstantSearch>
    </LayoutContainer>
  </OdsColorScheme>

  <div class="layout-default-header-search-panel__overlay" @click="closePanel"></div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-default-header-search-panel {
  $layout-default-header-search-panel-border: 2px solid var(--scheme--border);

  --layout-default-header-search-padding-top: var(--header-height, 90px);

  width: 100%;
  padding-top: var(--layout-default-header-search-padding-top);
  min-height: 300px;
  position: relative;
  z-index: 1;

  .layout-sticky-bar.layout-sticky-bar--sticky & {
    --layout-default-header-search-padding-top: 60px;
  }

  @media screen and (width < #{ods.$breakpoint-l}) {
    padding-left: 0;
    padding-right: 0;
  }

  @media screen and (width < #{ods.$breakpoint-xl}) {
    --layout-default-header-search-padding-top: 120px;

    .layout-sticky-bar:not(.layout-sticky-bar--sticky) & {
      --layout-default-header-search-padding-top: 140px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    max-height: 100dvh;
    overflow-y: auto;

    @media screen and (min-width: ods.$breakpoint-l) {
      flex-direction: row;
    }
  }

  &__see-more {
    font-size: 0.75em;
    padding: var(--spacing--xs) var(--spacing--s);
  }

  &__header {
    display: flex;
    align-items: center;
    gap: var(--spacing--s);
    margin: 0 0 var(--spacing--s) 0;
    justify-content: space-between;

    @media screen and (min-width: ods.$breakpoint-l) {
      justify-content: start;
    }
  }

  &__title {
    @include ods.h5;

    margin: 0;
  }

  &__navigation {
    display: flex;
    flex-direction: column;
    border-bottom: $layout-default-header-search-panel-border;
    font-size: ods.px-to-em(14px);

    @media screen and (min-width: ods.$breakpoint-l) {
      flex: 0 0 400px;
      border-bottom: none;
      border-right: $layout-default-header-search-panel-border;
    }
  }

  &__categories {
    grid-area: categories;
  }

  &__brands {
    border-top: $layout-default-header-search-panel-border;
  }

  &__products {
    width: 100%;
  }

  &__categories,
  &__brands,
  &__products {
    padding: var(--spacing--m);
  }

  &__brands,
  &__categories {
    @media screen and (min-width: ods.$breakpoint-l) {
      padding-left: 0;
    }
  }

  &__overlay {
    background: var(--greyscale--black);
    opacity: 0.3;
    width: 100dvw;
    height: 100dvh;
    position: fixed;
    top: 0;
    left: 0;
  }
}
</style>

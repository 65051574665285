<i18n lang="json" locale="fr-FR">
{
  "action": {
    "logIn": {
      "label": "@:{'global.action.logIn'}",
      "title": "@:{'action.logIn.label'}"
    },
    "viewAllProducts": {
      "label": "@:{'global.action.viewAllProducts.label'}",
      "title": "@:{'global.action.viewAllProducts.title'}"
    }
  }
}
</i18n>

<script setup lang="ts">
import { OdsIcon } from '@onliz/design-system-vue';
import { useMounted } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

import { PRODUCT_NAV_ITEM_ID } from '~/composables/layout/default/header/useHeaderNavData';
import type { UseMainNavComposable } from '~/composables/useMainNav';
import type { NavItemConfig } from '~/utils/MainNavUtils';
import { injectOrFail } from '~/utils/ReactivityUtils';

defineProps<{
  navTree: NavItemConfig[];
}>();

const { t } = useI18n();

const isSideMenuActive = injectOrFail<UseMainNavComposable['isSideMenuActive']>('isSideMenuActive');
const setActiveNodeId = injectOrFail<UseMainNavComposable['setActiveNodeId']>('setActiveNodeId');
const isActiveNodeId = injectOrFail<UseMainNavComposable['isActiveNodeId']>('isActiveNodeId');
const clearActiveNode = injectOrFail<UseMainNavComposable['clearActiveNode']>('clearActiveNode');
const isMegaMenu = injectOrFail<UseMainNavComposable['isMegaMenu']>('isMegaMenu');
const layerMap = injectOrFail<UseMainNavComposable['layerMap']>('layerMap');
const SIDE_NAV_ID = injectOrFail<string>('SIDE_NAV_ID');
const getNavItemProps = injectOrFail<UseMainNavComposable['getNavItemProps']>('getNavItemProps');
const onNavItemClick = injectOrFail<UseMainNavComposable['onNavItemClick']>('onNavItemClick');

const onGoBack = (parentId?: NavItemConfig['id']) => {
  if (parentId === undefined) {
    clearActiveNode();
  } else {
    setActiveNodeId(parentId);
  }
};

const onSideMenuStateUpdate = (isActive: boolean) => {
  isSideMenuActive.value = isActive;

  if (!isActive) {
    // TODO wait for the end of close transition to clear active node
    clearActiveNode();
  }
};

const isMounted = useMounted();
const { isAuthenticated } = useAuth();
const currentRoute = useRoute();
const signInRoute = useSignInRoute(currentRoute.fullPath);
</script>

<template>
  <LayoutDefaultHeaderSideMenu
    :id="SIDE_NAV_ID"
    :active="isSideMenuActive && !isMegaMenu"
    :hidden="!isMounted || isMegaMenu"
    class="layout-default-header-side-nav"
    @update:active="onSideMenuStateUpdate"
  >
    <template v-if="!isAuthenticated" #header-append>
      <LayoutDefaultHeaderSideMenuHeaderButton
        :title="t('action.logIn.title')"
        :label="t('action.logIn.label')"
        class="layout-default-header-side-nav__log-in"
        :href="signInRoute"
      >
        <template #prepend>
          <OdsIcon name="person" aria-hidden="true" />
        </template>
      </LayoutDefaultHeaderSideMenuHeaderButton>
    </template>

    <template #body>
      <div class="layout-default-header-side-nav__layer-wrapper">
        <LayoutDefaultHeaderSideMenuLayer
          class="layout-default-header-side-nav__layer layout-default-header-side-nav__layer--root"
          disable-go-back
          active
        >
          <LayoutDefaultHeaderSideMenuNavList
            v-for="{ id, children } in navTree"
            :key="id"
            :items="children"
            class="layout-default-header-side-nav__list"
          >
            <template #default="{ item }">
              <LayoutDefaultHeaderSideMenuNavItem
                v-bind="getNavItemProps(item, layerMap.navLayers.get(id)?.sideNavId)"
                @click="() => onNavItemClick(item)"
              />
            </template>
          </LayoutDefaultHeaderSideMenuNavList>
        </LayoutDefaultHeaderSideMenuLayer>

        <LayoutDefaultHeaderSideMenuLayer
          v-for="[id, { item, parentId, sideNavId, ancestorIdList }] in layerMap.navLayers"
          :id="sideNavId"
          :key="id"
          class="layout-default-header-side-nav__layer"
          :active="isActiveNodeId(item.id)"
          @go-back="() => onGoBack(parentId)"
        >
          <template #title>
            <div class="layout-default-header-side-nav__layer-title">
              <LayoutDefaultHeaderNavIcon
                v-if="item.icon"
                :config="item.icon"
                class="layout-default-header-side-nav__layer-title-icon"
              />

              <span v-if="item.label" class="layout-default-header-side-nav__layer-title-label">{{ item.label }}</span>
            </div>
          </template>

          <template #body>
            <LayoutDefaultHeaderSideMenuNavItem
              v-if="item.id === PRODUCT_NAV_ITEM_ID || ancestorIdList.includes(PRODUCT_NAV_ITEM_ID)"
              :to="item.to"
              :title="t('action.viewAllProducts.title')"
              class="layout-default-header-side-nav__view-all-products-button"
            >
              {{ t('action.viewAllProducts.label') }}
            </LayoutDefaultHeaderSideMenuNavItem>

            <LayoutDefaultHeaderSideMenuNavList :items="item.children" class="layout-default-header-side-nav__list">
              <template #default="{ item: navItem }">
                <LayoutDefaultHeaderSideMenuNavItem
                  v-bind="getNavItemProps(navItem, sideNavId)"
                  @click="() => onNavItemClick(navItem)"
                />
              </template>
            </LayoutDefaultHeaderSideMenuNavList>
          </template>
        </LayoutDefaultHeaderSideMenuLayer>
      </div>
    </template>
  </LayoutDefaultHeaderSideMenu>
</template>

<style lang="scss">
.layout-default-header-side-nav {
  .layout-default-header-side-menu__body {
    padding-bottom: 0;
  }

  &__log-in {
    margin-left: auto;
  }

  &__layer-wrapper {
    height: 100%;
    display: grid;
    grid-template-areas: 'content';
    overflow: hidden;
  }

  &__layer {
    grid-area: content;
    overflow: auto;
    overscroll-behavior: contain;
    background: var(--scheme--background);

    &--active {
      z-index: 1;
    }
  }

  &__list {
    & + & {
      border-top: solid 1px var(--scheme--border);
      margin-top: var(--spacing--s);
      padding-top: var(--spacing--s);
    }
  }

  &__view-all-products-button {
    font-size: var(--default--font-size);
    text-decoration: underline;
    color: inherit;
  }

  &__layer-title {
    display: flex;
    align-items: center;
    gap: var(--spacing--s);
  }

  &__layer-title-icon {
    flex: none;
  }

  &__layer-title-label {
    flex: 1;
  }
}
</style>

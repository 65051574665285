<script setup lang="ts">
import type { BreadcrumbParameters } from '~/composables/useBreadcrumb';

const breadcrumbParameters = ref<BreadcrumbParameters | undefined>();

provide('breadcrumbParameters', breadcrumbParameters);

const { items } = await useBreadcrumb(breadcrumbParameters);

useBreadcrumbJsonLd(items);

useRelCanonical();

await useUseOrganizationJsonLd();
</script>

<template>
  <div class="layout-default">
    <div class="layout-default__container">
      <LayoutDefaultHeader />

      <div class="layout-default__main">
        <BreadcrumbNav v-if="items.length > 1" :items />

        <slot></slot>
      </div>

      <LayoutDefaultFooter />
    </div>
  </div>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;
@use '~/assets/scss/mixins-utils' as mixins;

.layout-default {
  &__container {
    @include mixins.full-height-container;

    display: flex;
    flex-direction: column;
  }

  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>

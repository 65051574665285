import { useMediaQuery } from '@vueuse/core';
import { reactive, type Ref, ref } from 'vue';

import { PRODUCT_NAV_ITEM_ID } from '~/composables/layout/default/header/useHeaderNavData';
import { type NavItemConfig, sideMenuNavScrollElementId } from '~/utils/MainNavUtils';
import { slugify } from '~/utils/StringUtils';
import {
  clearActiveNodes,
  createFromExistingTree,
  isActiveNodeId as thIsActiveNodeId,
  setActiveNodeId as thSetActiveNodeId,
} from '~/utils/TreeHelper';

interface NavItemLayerAbstract {
  item: NavItemConfig;
  parentId: NavItemConfig['id'] | undefined;
}

export interface NavItemLayerNav extends NavItemLayerAbstract {
  megaMenuId: string;
  sideNavId: string;
  ancestorIdList: NavItemConfig['id'][];
  asideToDisplay: NavItemConfig['id'] | undefined;
}

export interface NavItemLayerAside extends NavItemLayerAbstract {
  asideId: string;
}

interface NavItemComputed {
  navLayers: Map<NavItemConfig['id'], NavItemLayerNav>;
  asideLayers: Map<NavItemConfig['id'], NavItemLayerAside>;
}

type AriaHasPopup = 'false' | 'true' | 'menu' | 'listbox' | 'tree' | 'grid' | 'dialog';

type NavItemProps = Pick<
  Partial<NavItemConfig>,
  'href' | 'title' | 'to' | 'icon' | 'label' | 'target' | 'onClick' | 'children'
> & {
  active?: boolean;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'aria-haspopup'?: AriaHasPopup;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'aria-expanded'?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'aria-controls'?: string;
};

export class MainNavError extends Error {}

export default function useMainNav(navTree: Ref<NavItemConfig[] | null>) {
  const isMegaMenu = useMediaQuery('(min-width: 75em)');
  const isSideMenuActive = ref(false);
  const { lock, unlock } = useScrollLock(sideMenuNavScrollElementId);

  const navTreeHelper = computed(() => {
    if (navTree.value) {
      return reactive(createFromExistingTree(navTree.value, 'id', 'children'));
    }

    return;
  });

  const hasActiveNode = computed<boolean>(() => !!navTreeHelper.value?.activeNodeIdList.size);
  const isMegaMenuActive = computed(() => hasActiveNode.value);

  const clearActiveNode = () => {
    if (!navTreeHelper.value) {
      throw new MainNavError('Failed to clear active node ID');
    }

    clearActiveNodes(navTreeHelper.value);

    if (isMegaMenu.value) {
      isSideMenuActive.value &&= false;
    }
  };

  const setActiveNodeId = (nodeId: NavItemConfig['id']) => {
    if (!navTreeHelper.value) {
      throw new MainNavError('Failed to set active node ID');
    }

    thSetActiveNodeId(navTreeHelper.value, nodeId);

    isSideMenuActive.value ||= true;
  };

  const toggleMenu = () => {
    if (isMegaMenu.value) {
      if (hasActiveNode.value) {
        clearActiveNode();
      } else {
        // Assuming we're displaying the product nav item by default
        setActiveNodeId(PRODUCT_NAV_ITEM_ID);
      }
    } else {
      if (isSideMenuActive.value) {
        isSideMenuActive.value = false;
        unlock();

        if (hasActiveNode.value) {
          clearActiveNode();
        }
      } else {
        isSideMenuActive.value = true;
        lock();
      }
    }
  };

  const isActiveNodeId = (nodeId: NavItemConfig['id'], isExactActive = false) => {
    if (navTreeHelper.value) {
      return thIsActiveNodeId(navTreeHelper.value, nodeId, isExactActive);
    }

    return false;
  };

  const activeNodeId = computed<NavItemConfig['id'] | undefined>(() => {
    if (!navTreeHelper.value) {
      throw new MainNavError('Failed to get active node ID');
    }

    return [...navTreeHelper.value.activeNodeIdList][0];
  });

  const isActive = computed<boolean>(() => {
    return isMegaMenu.value ? isMegaMenuActive.value : isSideMenuActive.value;
  });

  const processNavTree = (
    initialValue: NavItemComputed,
    tree: NavItemConfig[],
    ancestorIdList: NavItemConfig['id'][] = [],
    parentId?: NavItemConfig['id'],
    rootId?: NavItemConfig['id'],
    asideToDisplay?: NavItemConfig['id'],
  ): NavItemComputed => {
    return tree.reduce<NavItemComputed>((acc, item) => {
      let slugIdCache: string | undefined;
      const getLazySlugId = () => (slugIdCache ??= slugify(item.id.toString()));

      if (item.aside) {
        initialValue.asideLayers.set(item.id, {
          item,
          parentId,
          asideId: `layout-default-header-nav-bar-aside-layer-id--${getLazySlugId()}`,
        });
      }

      if (item.children?.length) {
        if (parentId !== undefined) {
          initialValue.navLayers.set(item.id, {
            item,
            parentId,
            ancestorIdList,
            megaMenuId: `layout-default-header-nav-bar-id--${getLazySlugId()}`,
            sideNavId: `layout-default-header-side-nav-layer-id--${getLazySlugId()}`,
            asideToDisplay: item.aside ? item.id : asideToDisplay,
          });
        }

        processNavTree(
          initialValue,
          item.children,
          [item.id, ...ancestorIdList],
          item.id,
          rootId ?? item.id,
          item.aside ? item.id : asideToDisplay,
        );
      }

      return acc;
    }, initialValue);
  };

  const layerMap = computed<NavItemComputed>(() => {
    if (!navTree.value) {
      throw new MainNavError('Failed to extract layers');
    }

    return processNavTree(
      {
        navLayers: new Map<NavItemConfig['id'], NavItemLayerNav>(),
        asideLayers: new Map<NavItemConfig['id'], NavItemLayerAside>(),
      },
      navTree.value,
    );
  });

  const getNavItemProps = (item: NavItemConfig, controlId?: string): NavItemProps => {
    const { href, title, to, icon, label, target, onClick, children, id } = item;
    const isActive = isActiveNodeId(id);

    const props: NavItemProps = {
      title,
      icon,
      label,
      target,
      onClick,
      children,
      active: isActive,
    };

    if (children?.length) {
      props['aria-haspopup'] = 'true';
      props['aria-expanded'] = String(isActive);

      if (controlId) {
        props['aria-controls'] = controlId;
      }
    } else {
      if (href) {
        props.href = href;
      }

      if (to) {
        props.to = to;
      }
    }

    return props;
  };

  const onNavItemClick = (item: NavItemConfig, parentId?: NavItemConfig['id']) => {
    if (item.children?.length) {
      if (isActiveNodeId(item.id)) {
        if (parentId === undefined) {
          clearActiveNode();

          isSideMenuActive.value &&= false;
        } else {
          setActiveNodeId(parentId);
        }
      } else {
        setActiveNodeId(item.id);
      }
    } else {
      clearActiveNode();

      isSideMenuActive.value &&= false;
    }
  };

  return {
    isMegaMenu,
    isSideMenuActive,
    toggleMenu,
    isActiveNodeId,
    isActive,
    setActiveNodeId,
    clearActiveNode,
    isMegaMenuActive,
    layerMap,
    getNavItemProps,
    onNavItemClick,
    activeNodeId,
  };
}

export type UseMainNavComposable = ReturnType<typeof useMainNav>;

<i18n lang="json" locale="fr-FR">
{
  "openingHours": "@:{'global.support.openingHours'}",
  "open": "Nos conseillers sont disponibles {openingHours}",
  "openShort": "Disponible {openingHours}",
  "closed": "Une question ? Besoin d'aide ? {contactLink}",
  "contactUs": "Contactez-nous !"
}
</i18n>

<script setup lang="ts">
import { OdsColorScheme } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import { useNow } from '@vueuse/core';
import { useI18n } from 'vue-i18n';

import { customerServiceSchedules, isCustomerServiceOpen } from '~/utils/CustomerServiceUtils';

const { t } = useI18n();
const openingHoursLabel = t('openingHours', customerServiceSchedules);

const fifteenMinutesInMs = 900_000;
const time = useNow({
  interval: fifteenMinutesInMs,
});

const customerServiceIsOpen = computed<boolean>(() => isCustomerServiceOpen(time.value));
const contactLink = helpers.route({ name: 'contact' });
</script>

<template>
  <OdsColorScheme scheme="dark" skip-root-tag>
    <LayoutContainer class="layout-default-pre-header">
      <div class="layout-default-pre-header__wrapper">
        <ClientOnly>
          <template v-if="customerServiceIsOpen">
            <PhoneBadge />

            <i18n-t keypath="open" class="layout-default-pre-header__label-open" tag="span">
              <template #openingHours>
                {{ openingHoursLabel }}
              </template>
            </i18n-t>

            <i18n-t keypath="openShort" class="layout-default-pre-header__label-open-short" tag="span">
              <template #openingHours>
                {{ openingHoursLabel }}
              </template>
            </i18n-t>
          </template>

          <i18n-t v-else keypath="closed">
            <template #contactLink>
              <NuxtLink :to="contactLink">
                {{ t('contactUs') }}
              </NuxtLink>
            </template>
          </i18n-t>
        </ClientOnly>
      </div>
    </LayoutContainer>
  </OdsColorScheme>
</template>

<style lang="scss">
@use '@onliz/design-system-scss' as ods;

.layout-default-pre-header {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(var(--spacing--xs) * 2 + var(--default--line-height) * var(--default--font-size-px));

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: var(--spacing--s);
    padding: var(--spacing--xs) 0;
    font-size: var(--small--font-size);
  }

  &__icon {
    flex: 0 0 1em;
  }

  &__label-open {
    display: none;

    @media screen and (min-width: ods.$breakpoint-s) {
      display: block;
    }
  }

  &__label-open-short {
    display: block;

    @media screen and (min-width: ods.$breakpoint-s) {
      display: none;
    }
  }
}
</style>

<script setup lang="ts">
import { OdsClickableElement } from '@onliz/design-system-vue';
import { helpers } from '@typed-router';
import { AisRefinementList } from 'vue-instantsearch/vue3/es';
import type { RouteLocationRaw } from 'vue-router';

const getBrandRoute = function (brand: string): RouteLocationRaw {
  return helpers.route({
    name: 'p-search',
    query: { brand },
  });
};
</script>

<template>
  <AisRefinementList v-slot="{ items }" attribute="brand" :limit="3">
    <ol class="layout-default-header-search-panel-brands">
      <li v-for="brand in items" :key="brand.value" class="layout-default-header-search-panel-brands__item">
        <OdsClickableElement class="layout-default-header-search-panel-brands__link" :to="getBrandRoute(brand.value)">
          {{ brand.value }}
        </OdsClickableElement>
      </li>
    </ol>
  </AisRefinementList>
</template>

<style lang="scss">
.layout-default-header-search-panel-brands {
  padding: 0;
  margin: 0;

  &__link {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>

<script setup lang="ts">
import { type Component, computed } from 'vue';

import LayoutDefaultHeaderNavIconImg from '~/components/layout/default/header/nav/icon/LayoutDefaultHeaderNavIconImg.vue';
import LayoutDefaultHeaderNavIconOdsIcon from '~/components/layout/default/header/nav/icon/LayoutDefaultHeaderNavIconOdsIcon.vue';
import LayoutDefaultHeaderNavIconStrapiIcon from '~/components/layout/default/header/nav/icon/LayoutDefaultHeaderNavIconStrapiIcon.vue';
import { type NavItemConfigIcon } from '~/utils/MainNavUtils';

const props = defineProps<{ config: NavItemConfigIcon }>();

const componentMap: Record<NavItemConfigIcon['type'], Component> = {
  img: LayoutDefaultHeaderNavIconImg,
  'ods-icon': LayoutDefaultHeaderNavIconOdsIcon,
  'strapi-icon': LayoutDefaultHeaderNavIconStrapiIcon,
};

const component = computed(() => {
  const { type, ...propsToBind } = props.config;

  return { component: componentMap[type], propsToBind };
});
</script>

<template>
  <Component :is="component.component" v-bind="component.propsToBind" class="layout-default-header-nav-icon" />
</template>

<style lang="scss">
.layout-default-header-nav-icon {
  --dynamic-icon--size: 1.125rem;

  display: flex;
  place-items: center;
}
</style>
